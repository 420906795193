<template>
    <div class="section-2">
        <div class="mx-auto">
            <div class="lg:grid lg:grid-cols-2 lg:items-center">
                
                <div>
                    <img src="@/assets/img/Move-Itaigara-Mobilidade.jpg" class="object-cover w-11/12" alt="Move Itaigara - Mobilidade">
                </div>
                <div class="2xl:flex flex-col 2xl:justify-end lg:pt-6">  
                
                    <div class="px-14 pt-14 pb-8 md:p-14 text-justify text-sm xl:p-0 xl:ml-20 mr-auto xl:text-lg text-gray-700 lg:text-left lg:w-10/12 space-y-8 leading-6 lg:mb-20">
                        <h1 class="text-xl xl:text-2xl text-left text-orange-400 uppercase font-semibold 2xl:text-3xl">MUITO MAIS MOBILIDADE NO SEU DIA A DIA</h1>
                        <p>O <strong> Move </strong> foi pensado em cada detalhe
                        para integrar o seu estilo de vida ao 
                        lugar onde você vive. Com escolas, bancos, shoppings, farmácias, clínicas, 
                        supermercados e parques a uma curta caminhada de distância. Tudo para 
                        você aproveitar o seu tempo com o que realmente importa</p>
                    </div> 
                    <div class="w-full flex justify-end div-button overflow-hidden mb-10 lg:mb-0" >
                        <div @click="$emit('openModal', 'Saiba Mais')" class="bg-orange-400 relative button text-white w-10/12 p-2 lg:p-4 lg:w-4/6 2xl:w-2/4 text-center skew cursor-pointer">
                            <div class="text-xl uppercase skew-text flex justify-center space-x-3">
                                <span> Saiba Mais</span>
                            </div>
                        </div>
                    </div>
                </div>
                
            
                
                
            </div>
        </div>
        
        
    </div>
   
</template>
<script>
export default {
    name: "SectionTwo",
    emits: ['openModal'],
}
</script>
<style scoped>    
    .skew{
        transform: skew(30deg);
        -webkit-transform: skew(30deg);
        -moz-transform: skew(30deg);
        -o-transform: skew(30deg);
        margin-right: -3%;
    }
    .skew-text{
        transform: skew(-30deg);
        -webkit-transform: skew(-30deg);
        -moz-transform: skew(-30deg);
        -o-transform: skew(-30deg);
         margin-right: -3%;
    }
    
    .button{
        transition: all 0.5s;
        border-top: 2px solid rgb(255 255 255);
        border-bottom: 2px solid rgb(255 255 255);
    }
    .button:after{
        content: '';
        width: 32px;
        top: 0px;
        left: -1px;
        height: 85%;
        display: block;
        background: rgb(251 146 60);
        position: absolute;
        transition: all 1s;
        box-shadow: -10px 9px rgb(251 146 60);
        border-left: 2px solid rgb(255, 255, 255);
        border-bottom: 2px solid rgb(255, 255, 255);
    }
    .button:hover{
        border-top: 2px solid rgb(251 146 60);
        border-bottom: 2px solid rgb(251 146 60) !important;
        background: rgb(255, 255, 255);
        color: rgb(251 146 60);
    }
    .button:hover svg{
        fill: rgb(251 146 60);
    }
    .button:hover:after{
        border-left: 2px solid rgb(255, 255, 255);
        box-shadow: -10px 12px rgb(251 146 60) !important;
        border-bottom: 2px solid rgb(255, 255, 255);
    }

</style>