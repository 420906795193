<template>
    <footer class="w-full">
        <img src="@/assets/img/move.png" alt="Move Itaigara">
        <div class="flex flex-col justify-center">
            <h2 class="text-orange-400 text-3xl text-center uppercase">Visite nosso stand</h2>
            <p class="text-orange-400 text-2xl text-center uppercase">rua sílvio valente, 233 - itaigara</p>
            <div class="text-orange-400 text-2xl text-center uppercase mb-14 flex justify-center mt-3">
                <p>71 3461-4444 </p> 
                <span class="mx-2">-</span> 
                <a :href="`https://wa.me/5571999691478?text=Olá! ${$route.query.utm_source && $route.query.utm_source != 'direct' ? 'Vi seu anúncio no ' + $route.query.utm_source + '.' :''}%20Gostaria%20de%20saber%20mais%20sobre%20o%20Move%20Itaigara`" target="_blank" title="WhatsApp"> 
                    71 99969-1478
                    <svg class="inline ml-1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="rgb(251 146 60)" viewBox="0 0 16 16">
                        <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
                    </svg>
                </a>
            </div>

            <h2 class="text-gray-700 text-2xl text-center uppercase mb-4">Realização</h2>
            <div class="md:max-w-3xl md:grid md:gap-4 md:grid-cols-2 mx-auto md:items-center">
                <div>
                    <img src="@/assets/img/SERTENGE.png" alt="Sertenge" class="mx-auto w-auto max-w-full">
                </div>
                <div>
                    <img src="@/assets/img/BRADESCO.png" alt="Bradesco" class="mx-auto w-auto max-w-full">
                </div>
                
            </div>
            <div class="p-2 xl:p-14 py-4 text-justify">
                <p class="text-xs">
                    Em atenção à lei 4.591/64, de 16/12/64, às previsões legislativas aplicadas ao condomínio edifício trazidas pelos artigos 1.331 a 1.358 do Código Civil de 2002, bem como previsões trazidas pela Lei no 11.977/09 e demais normas legais aplicáveis, informamos que as fotos, perspectivas, equipamentos, móveis, 
                    iluminação, vegetação e paisagismo, inclusive espécies vegetais e porte das mesmas, utilizados neste material têm caráter meramente ilustrativo, por tratar-se de um bem a ser construído. As condições de entrega do empreendimento encontram-se no Contrato de Compra e Venda. Por motivos técnicos e/
                    ou processos construtivos poderão ocorrer modificações quanto às dimensões e/ou layout. Por se tratar de um material impresso, as imagens poderão não retratar fielmente as cores naturais dos materiais. Responsável técnico: Antônio Santos Filgueiras CREA/BA: 7542-D. Projeto Arquitetônico: Eduardo José Duarte Brandão – 
                    CAU A2892-4. Registro de Incorporação matrícula no 74.298 do Cartório do 6° Registro de Imóveis de Salvador/BA. CRECI PJ 1882 e CRECI PJ 1143. As áreas privativas encontram-se nos projetos disponíveis no Stand de Vendas, nos Contratos de Compra e Venda e na planta legal aprovada junto à PMS e registrada no CR
                </p>
            </div>
        </div>
    </footer>
</template>
<script>
export default {
    name: 'FooterComponent'
}
</script>
<style scoped>
    
</style>