<template>
  <router-view/>
</template>
<script>
  
</script>
<style>
@font-face {
  font-family: Book;
  src: url(~@/assets/fonts/Uni-Sans-Book.otf)
}
@font-face {
  font-family: Bold;
  src: url(~@/assets/fonts/Uni-Sans-Semi-Bold.otf)
}
#app {
  font-family: Book;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.font-bold{
  font-family: Bold !important;
}
.font-semibold{
  font-family: Bold !important;
}
html, body{
  margin: 0;
  padding: 0;
}
*{
  box-sizing: border-box;
}
.gslide-title{
  font-weight: bold !important;
  text-transform: uppercase;
}

body::-webkit-scrollbar {
  width: 10px;  
}

body::-webkit-scrollbar-track {
  background: transparent;       
}

body::-webkit-scrollbar-thumb {
  background-color: rgba(251, 146, 60, 0.5);    
  border-radius: 20px;       
  border: none;
}
body:hover::-webkit-scrollbar { width: 8px; }
body {
    overflow: overlay;
}
.glightbox-clean .gslide-description {
    background: #fb923c;
}
.glightbox-clean .gslide-description .gslide-title, .gslide-desc{
  color: #fff;
  text-transform: uppercase;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: white !important;
}
</style>
