<template>
    <div class="map w-full">
        <div class="lg:grid lg:grid-cols-4 lg:gap-4 w-full overflow-hidden">
            <div class="div-map lg:col-span-3">
                <div class="div-img">
                    <div class="inline-block relative">
                        <img src="@/assets/img/mapa/Move-Itaigara-Localizacao.jpg" class="img-mapa" alt="Move Itaigara - Localização" id="mapa">

                        <!-- Escolas -->
                        <span class="pin pin-1 pin-escola hidden" @mouseover="clicked">
                            <span class="bg-pin p-2 shadow-lg absolute w-36 mt-2 rounded-lg flex flex-col ">
                                <span class="text-gray-700 font-semibold text-center">Escola Girassol</span>
                                <span class="text-gray-700 text-center"> 3 min a pé</span>
                            </span>
                        </span>

                        <span class="pin pin-2 pin-escola hidden" @mouseover="clicked">
                            <span class="bg-pin p-2 shadow-lg absolute w-64 mt-2 rounded-lg flex flex-col ">
                                <span class="text-gray-700 font-semibold text-center">Colégio Nossa Senhora da Luz</span>
                                <span class="text-gray-700 text-center"> 4 min de carro</span>
                            </span>
                        </span>

                        <span class="pin pin-3 pin-escola hidden" @mouseover="clicked">
                            <span class="bg-pin p-2 shadow-lg absolute w-36 mt-2 xl:mt-7 rounded-lg flex flex-col ">
                                <span class="text-gray-700 font-semibold text-center">Escola Colmeia</span>
                                <span class="text-gray-700 text-center"> 5 min de carro</span>
                            </span>
                        </span>   
                        <span class="pin pin-3-1 pin-escola hidden" @mouseover="clicked">
                            <span class="bg-pin p-2 shadow-lg absolute w-36 mt-2 xl:mt-7 rounded-lg flex flex-col ">
                                <span class="text-gray-700 font-semibold text-center">Sartre</span>
                                <span class="text-gray-700 text-center"> 4 min de carro</span>
                            </span>
                        </span>   
                        <!-- FIm Escolas -->

                        <!-- Shoppin -->
                        <span class="pin pin-4 pin-shoping hidden" @mouseover="clicked">
                            <span class="bg-pin p-2 shadow-lg absolute w-36 mt-2 xl:mt-7 rounded-lg flex flex-col ">
                                <span class="text-gray-700 font-semibold text-center">Shopping Itaigara</span>
                                <span class="text-gray-700 text-center"> 4 min a pé</span>
                            </span>
                        </span> 

                        <span class="pin pin-5 pin-shoping hidden" @mouseover="clicked">
                            <span class="bg-pin p-2 shadow-lg absolute w-44 mt-2 xl:mt-7 rounded-lg flex flex-col ">
                                <span class="text-gray-700 font-semibold text-center">Boulevard 161 Itaigara</span>
                                <span class="text-gray-700 text-center"> 3 min de carro</span>
                            </span>
                        </span> 

                        <span class="pin pin-6 pin-shoping hidden" @mouseover="clicked">
                            <span class="bg-pin p-2 shadow-lg absolute w-36 mt-2 xl:mt-7 rounded-lg flex flex-col ">
                                <span class="text-gray-700 font-semibold text-center">Tricenter</span>
                                <span class="text-gray-700 text-center"> 5 min de carro</span>
                            </span>
                        </span> 

                        <span class="pin pin-7 pin-shoping hidden" @mouseover="clicked">
                            <span class="bg-pin p-2 shadow-lg absolute w-36 mt-2 xl:mt-7 rounded-lg flex flex-col ">
                                <span class="text-gray-700 font-semibold text-center">Shopping Cidade</span>
                                <span class="text-gray-700 text-center"> 5 min de carro</span>
                            </span>
                        </span> 

                        <span class="pin pin-8 pin-shoping hidden" @mouseover="clicked">
                            <span class="bg-pin p-2 shadow-lg absolute w-36 mt-2 xl:mt-7 rounded-lg flex flex-col ">
                                <span class="text-gray-700 font-semibold text-center">Passeo Itaigara</span>
                                <span class="text-gray-700 text-center"> 3 min de carro</span>
                            </span>
                        </span> 
                        <!-- FIm Shoppings -->

                        <!-- Parques/Praças -->

                        <span class="pin pin-9 pin-pracas-parques hidden" @mouseover="clicked">
                            <span class="bg-pin p-2 shadow-lg absolute w-52 mt-2 xl:mt-10 rounded-lg flex flex-col ">
                                <span class="text-gray-700 font-semibold text-center">Praça Ana Lúcia Magalhães</span>
                                <span class="text-gray-700 text-center"> 4 min de carro</span>
                            </span>
                        </span> 

                         <span class="pin pin-10 pin-pracas-parques hidden" @mouseover="clicked">
                            <span class="bg-pin p-2 shadow-lg absolute w-36 mt-2 xl:mt-0 rounded-lg flex flex-col ">
                                <span class="text-gray-700 font-semibold text-center">Praça Myrian Fraga</span>
                                <span class="text-gray-700 text-center"> 1 min a pé</span>
                            </span>
                        </span> 

                         <span class="pin pin-11 pin-ponto-brt hidden" @mouseover="clicked">
                            <span class="bg-pin p-2 shadow-lg absolute w-36 mt-2 xl:mt-7 rounded-lg flex flex-col ">
                                <span class="text-gray-700 font-semibold text-center">Ponto BRT</span>
                                <span class="text-gray-700 text-center"> 5 min a pé</span>
                            </span>
                        </span> 

                        <span class="pin pin-12 pin-pracas-parques hidden" @mouseover="clicked">
                            <span class="bg-pin p-2 shadow-lg absolute w-36 mt-2 xl:mt-7 rounded-lg flex flex-col ">
                                <span class="text-gray-700 font-semibold text-center">Parque da cidade</span>
                                <span class="text-gray-700 text-center"> 5 min de carro</span>
                            </span>
                        </span> 

                        <!-- Fim Parques/Praças -->

                        <!-- restaurantes/lanchonetes -->

                        <span class="pin pin-13 pin-restaurantes-lanchonetes hidden" @mouseover="clicked">
                            <span class="bg-pin p-2 shadow-lg absolute w-36 mt-2 xl:mt-7 rounded-lg flex flex-col ">
                                <span class="text-gray-700 font-semibold text-center">MC Donalds</span>
                                <span class="text-gray-700 text-center"> 4 min de carro</span>
                            </span>
                        </span> 
                        <span class="pin pin-14 pin-restaurantes-lanchonetes hidden" @mouseover="clicked">
                            <span class="bg-pin p-2 shadow-lg absolute w-36 mt-2 xl:mt-7 rounded-lg flex flex-col ">
                                <span class="text-gray-700 font-semibold text-center">Pizza da Chapada</span>
                                <span class="text-gray-700 text-center"> 2 min a pé</span>
                            </span>
                        </span>

                        <span class="pin pin-15 pin-restaurantes-lanchonetes hidden" @mouseover="clicked">
                            <span class="bg-pin p-2 shadow-lg absolute w-36 mt-2 xl:mt-7 rounded-lg flex flex-col ">
                                <span class="text-gray-700 font-semibold text-center">Caminho de casa</span>
                                <span class="text-gray-700 text-center"> 4 min de carro</span>
                            </span>
                        </span>

                        <span class="pin pin-15-1 pin-restaurantes-lanchonetes hidden" @mouseover="clicked">
                            <span class="bg-pin p-2 shadow-lg absolute w-36 mt-2 xl:mt-7 rounded-lg flex flex-col ">
                                <span class="text-gray-700 font-semibold text-center">Tortarelli</span>
                                <span class="text-gray-700 text-center"> 4 min de carro</span>
                            </span>
                        </span>

                        <span class="pin pin-15-2 pin-restaurantes-lanchonetes hidden" @mouseover="clicked">
                            <span class="bg-pin p-2 shadow-lg absolute w-36 mt-2 xl:mt-16 rounded-lg flex flex-col ">
                                <span class="text-gray-700 font-semibold text-center">Rocca Forneria</span>
                                <span class="text-gray-700 text-center"> 4 min de carro</span>
                            </span>
                        </span>  

                        <span class="pin pin-15-3 pin-restaurantes-lanchonetes hidden" @mouseover="clicked">
                            <span class="bg-pin p-2 shadow-lg absolute w-36 mt-2 xl:mt-7 rounded-lg flex flex-col ">
                                <span class="text-gray-700 font-semibold text-center">Bóia Cozinha do Mar</span>
                                <span class="text-gray-700 text-center"> 5 min de carro</span>
                            </span>
                        </span>

                        <span class="pin pin-15-4 pin-restaurantes-lanchonetes hidden" @mouseover="clicked">
                            <span class="bg-pin p-2 shadow-lg absolute w-36 mt-2 xl:mt-7 rounded-lg flex flex-col ">
                                <span class="text-gray-700 font-semibold text-center">Cantina Volpi</span>
                                <span class="text-gray-700 text-center"> 5 min de carro</span>
                            </span>
                        </span>

                        <span class="pin pin-15-5 pin-restaurantes-lanchonetes hidden" @mouseover="clicked">
                            <span class="bg-pin p-2 shadow-lg absolute w-36 mt-2 xl:mt-7 rounded-lg flex flex-col ">
                                <span class="text-gray-700 font-semibold text-center">Soho</span>
                                <span class="text-gray-700 text-center"> 5 min de carro</span>
                            </span>
                        </span>
                        <!-- Fim restaurantes/lanchonetes -->

                        <!-- alimentos saudáveis -->

                            <span class="pin pin-16 pin-alimentos-saudaveis hidden" @mouseover="clicked">
                                <span class="bg-pin p-2 shadow-lg absolute w-36 mt-2 xl:mt-7 rounded-lg flex flex-col ">
                                    <span class="text-gray-700 font-semibold text-center">Puro e Saudável</span>
                                    <span class="text-gray-700 text-center"> 5 min de carro</span>
                                </span>
                            </span>

                            <span class="pin pin-17 pin-alimentos-saudaveis hidden" @mouseover="clicked">
                                <span class="bg-pin p-2 shadow-lg absolute w-36 mt-2 xl:mt-7 rounded-lg flex flex-col ">
                                    <span class="text-gray-700 font-semibold text-center">Nultrimaster</span>
                                    <span class="text-gray-700 text-center"> 5 min de carro</span>
                                </span>
                            </span>

                            <span class="pin pin-17-1 pin-alimentos-saudaveis hidden" @mouseover="clicked">
                                <span class="bg-pin p-2 shadow-lg absolute w-36 mt-2 xl:mt-7 rounded-lg flex flex-col ">
                                    <span class="text-gray-700 font-semibold text-center">Açai & Saúde</span>
                                    <span class="text-gray-700 text-center"> 5 min de carro</span>
                                </span>
                            </span>

                            <span class="pin pin-17-2 pin-alimentos-saudaveis hidden" @mouseover="clicked">
                                <span class="bg-pin p-2 shadow-lg absolute w-36 mt-2 xl:mt-7 rounded-lg flex flex-col ">
                                    <span class="text-gray-700 font-semibold text-center">Saúde na Panela</span>
                                    <span class="text-gray-700 text-center"> 5 min de carro</span>
                                </span>
                            </span>

                        <!-- Fim alimentos saudáveis -->

                        <!-- supermercados -->

                            <span class="pin pin-18 pin-supermercados hidden" @mouseover="clicked">
                                <span class="bg-pin p-2 shadow-lg absolute w-36 mt-2 xl:mt-7 rounded-lg flex flex-col ">
                                    <span class="text-gray-700 font-semibold text-center">Super Bompreço</span>
                                    <span class="text-gray-700 text-center"> 5 min de carro</span>
                                </span>
                            </span>

                            <span class="pin pin-19 pin-supermercados hidden" @mouseover="clicked">
                                <span class="bg-pin p-2 shadow-lg absolute w-36 mt-2 xl:mt-7 rounded-lg flex flex-col ">
                                    <span class="text-gray-700 font-semibold text-center">Hiperideal</span>
                                    <span class="text-gray-700 text-center"> 3 min de carro</span>
                                </span>
                            </span>

                            <span class="pin pin-19-1 pin-supermercados hidden" @mouseover="clicked">
                                <span class="bg-pin p-2 shadow-lg absolute w-52 mt-2 xl:mt-7 rounded-lg flex flex-col ">
                                    <span class="text-gray-700 font-semibold text-center">Super Bompreço Itaigara</span>
                                    <span class="text-gray-700 text-center"> 5 min a pé</span>
                                </span>
                            </span>

                        <!-- Fim supermercados --> 

                        <!-- saloes de beleza -->

                            <span class="pin pin-20 pin-saloes hidden" @mouseover="clicked">
                                <span class="bg-pin p-2 shadow-lg absolute w-52 mt-2 xl:mt-2 rounded-lg flex flex-col ">
                                    <span class="text-gray-700 font-semibold text-center">L´Eqquipe Salão de Beleza </span>
                                    <span class="text-gray-700 text-center">  4 min de carro</span>
                                </span>
                            </span>
                            <span class="pin pin-20-1 pin-saloes hidden" @mouseover="clicked">
                                <span class="bg-pin p-2 shadow-lg absolute w-52 mt-2 xl:mt-7 rounded-lg flex flex-col ">
                                    <span class="text-gray-700 font-semibold text-center">Studio S </span>
                                    <span class="text-gray-700 text-center"> 3 min de carro</span>
                                </span>
                            </span>

                            <span class="pin pin-20-2 pin-saloes hidden" @mouseover="clicked">
                                <span class="bg-pin p-2 shadow-lg absolute w-52 mt-2 xl:mt-7 rounded-lg flex flex-col ">
                                    <span class="text-gray-700 font-semibold text-center">Dom Beauty Studio</span>
                                    <span class="text-gray-700 text-center"> 4 min a pé</span>
                                </span>
                            </span>

                        <!-- Fim saloes de beleza -->   

                        <!-- pet shops -->  
                            <span class="pin pin-21 pin-pet-shop hidden" @mouseover="clicked">
                                <span class="bg-pin p-2 shadow-lg absolute w-36 mt-2 xl:mt-7 rounded-lg flex flex-col ">
                                    <span class="text-gray-700 font-semibold text-center">Pet Master </span>
                                    <span class="text-gray-700 text-center">  3 min de carro</span>
                                </span>
                            </span>

                            <span class="pin pin-22 pin-pet-shop hidden" @mouseover="clicked">
                                <span class="bg-pin p-2 shadow-lg absolute w-36 mt-2 xl:mt-7 rounded-lg flex flex-col ">
                                    <span class="text-gray-700 font-semibold text-center">Agromix </span>
                                    <span class="text-gray-700 text-center">  4 min de carro</span>
                                </span>
                            </span>
                        <!-- Fim pet shops -->  

                        <!-- farmacias-->  
                            <span class="pin pin-23 pin-farmacia hidden" @mouseover="clicked">
                                <span class="bg-pin p-2 shadow-lg absolute w-36 mt-2 xl:mt-7 rounded-lg flex flex-col ">
                                    <span class="text-gray-700 font-semibold text-center">A Fórmula </span>
                                    <span class="text-gray-700 text-center">  4 min a pé</span>
                                </span>
                            </span>

                            <span class="pin pin-24 pin-farmacia hidden" @mouseover="clicked">
                                <span class="bg-pin p-2 shadow-lg absolute w-36 mt-2 xl:mt-7 rounded-lg flex flex-col ">
                                    <span class="text-gray-700 font-semibold text-center">Extrafarma </span>
                                    <span class="text-gray-700 text-center">  5 min de carro</span>
                                </span>
                            </span>

                        <!-- Fim farmacias -->  

                        <!-- Centros Mádicos-->

                            <span class="pin pin-25 pin-centros-medicos hidden" @mouseover="clicked">
                                <span class="bg-pin p-2 shadow-lg absolute w-52 mt-2 xl:mt-2 rounded-lg flex flex-col ">
                                    <span class="text-gray-700 font-semibold text-center">Sabin Medicina diagnóstica </span>
                                    <span class="text-gray-700 text-center">  3 min de carro</span>
                                </span>
                            </span>

                            <span class="pin pin-26 pin-centros-medicos hidden" @mouseover="clicked">
                                <span class="bg-pin p-2 shadow-lg absolute w-52 mt-2 xl:mt-2 rounded-lg flex flex-col ">
                                    <span class="text-gray-700 font-semibold text-center">Day Hospital Louis Pasteur </span>
                                    <span class="text-gray-700 text-center">  5 min de carro</span>
                                </span>
                            </span> 

                        <!-- Fim Centros Mádicos -->  

                        <!-- Bancos-->
                            <span class="pin pin-27 pin-bancos hidden" @mouseover="clicked">
                                <span class="bg-pin p-2 shadow-lg absolute w-32 mt-2 xl:mt-7 rounded-lg flex flex-col ">
                                    <span class="text-gray-700 font-semibold text-center">Bradesco </span>
                                    <span class="text-gray-700 text-center"> 1 min de carro </span>
                                </span>
                            </span>
                            <span class="pin pin-28 pin-bancos hidden" @mouseover="clicked">
                                <span class="bg-pin p-2 shadow-lg absolute w-52 mt-2 xl:mt-7 rounded-lg flex flex-col ">
                                    <span class="text-gray-700 font-semibold text-center">Itaú (Dentro do Max Center)</span>
                                    <span class="text-gray-700 text-center">  3 min de carro</span>
                                </span>
                            </span>
                        <!-- Fim Bancos --> 
                    </div>   
                </div>
            </div>
            <div class="div-map-menu bg-orange-400">
                <div class="div-menu flex flex-col justify-center">
                    <div class="bg-[#62614b] px-14 py-7 lg:p-14 title text-center flex flex-col">
                        <h2 class="text-white font-semibold mr-72 xl:mr-96 2xl:mr-72 text-xl 2xl:text-3xl mb-3">VOCÊ PERTO DE TUDO</h2>
                        <p  class="hidden xl:block text-white mr-72 xl:mr-96 2xl:mr-72 text-lg 2xl:text-xl">Passe o mouse pelos itens</p>
                        <p  class="xl:hidden text-white mr-72 xl:mr-96 2xl:mr-72 text-lg 2xl:text-xl">Clique em um item da lista e confira</p>
                        <p  class="hidden xl:block text-white mr-72 xl:mr-96 2xl:mr-72 text-lg 2xl:text-xl">da lista e confira</p>
                    </div>
                    <div class="flex mt-10 justify-center">
                        <ul class="list-decimal lista text-white 2xl:text-2xl" @mouseleave="hiddenPins">
                            <li><a href="#mapa" class="no-underline"  @mouseover="showPins('escola')">Escolas</a> </li>
                            <li> <a href="#mapa" class="no-underline" @mouseover="showPins('shoping')">Shopping</a></li>
                            <li> <a href="#mapa" class="no-underline" @mouseover="showPins('pracas-parques')">Parques/Praças</a></li>
                            <li> <a href="#mapa" class="no-underline" @mouseover="showPins('restaurantes-lanchonetes')">Restaurantes e Lanchonetes</a></li>
                            <li> <a href="#mapa" class="no-underline" @mouseover="showPins('alimentos-saudaveis')">Alimentos saudáveis</a></li>
                            <li> <a href="#mapa" class="no-underline" @mouseover="showPins('supermercados')">Supermercados</a></li>
                            <li> <a href="#mapa" class="no-underline" @mouseover="showPins('saloes')">Salões de beleza</a></li>
                            <li> <a href="#mapa" class="no-underline" @mouseover="showPins('pet-shop')">Pet shops</a></li>
                            <li> <a href="#mapa" class="no-underline" @mouseover="showPins('farmacia')">Farmácias</a></li>
                            <li> <a href="#mapa" class="no-underline" @mouseover="showPins('centros-medicos')">Centros médicos</a></li>
                            <li> <a href="#mapa" class="no-underline" @mouseover="showPins('bancos')">Bancos</a></li>
                            <li> <a href="#mapa" class="no-underline" @mouseover="showPins('ponto-brt')">Ponto BRT</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'MapaComponent',
    methods: {
         showPins(menu){
            this.hiddenPins();
            document.querySelectorAll(`.pin-${menu}`).forEach((el) => {
                el.classList.remove('hidden');
                el.classList.add('inline-block', 'opacity');
                if(menu == 'alimentos-saudaveis'){

                    document.querySelector('.pin-16').classList.remove('opacity');
                    document.querySelector('.pin-17').classList.remove('opacity');
                    document.querySelector('.pin-17-1').classList.remove('opacity');
                    document.querySelector('.pin-17-2').classList.remove('opacity');

                    document.querySelector('.pin-16').classList.add('chocando');
                    document.querySelector('.pin-17').classList.add('chocando-2');
                    document.querySelector('.pin-17-1').classList.add('chocando-2');
                    document.querySelector('.pin-17-2').classList.add('chocando');
                }else if(menu == 'restaurantes-lanchonetes'){
                    document.querySelector('.pin-15-1').classList.remove('opacity');
                    document.querySelector('.pin-15-3').classList.remove('opacity');
                    document.querySelector('.pin-15-4').classList.remove('opacity');
                    document.querySelector('.pin-15-5').classList.remove('opacity');

                    document.querySelector('.pin-15-1').classList.add('chocando');
                    document.querySelector('.pin-15-3').classList.add('chocando-2');
                    document.querySelector('.pin-15-4').classList.add('chocando');
                    document.querySelector('.pin-15-5').classList.add('chocando-2');
                }else if(menu == 'shoping'){

                    document.querySelector('.pin-5').classList.remove('opacity');
                    document.querySelector('.pin-6').classList.remove('opacity');

                    document.querySelector('.pin-5').classList.add('chocando');
                    document.querySelector('.pin-6').classList.add('chocando-2');
                }else if(menu == 'saloes'){
                    document.querySelector('.pin-20').classList.remove('opacity');
                    document.querySelector('.pin-20-1').classList.remove('opacity');

                    document.querySelector('.pin-20').classList.add('chocando');
                    document.querySelector('.pin-20-1').classList.add('chocando-2');
                }   
            })
        },
        hiddenPins(){
            document.querySelectorAll(`.pin`).forEach((el) => {
                el.classList.remove('inline-block', 'opacity');
                el.classList.add('hidden');
            })
            
        },
        clicked(el){
            if(el.target.classList.contains('pin')){
                el.target.classList.remove('hidden');
                el.target.classList.add('inline-block');
            }else{
                el.target.closest(".pin").classList.remove('hidden');
                el.target.closest(".pin").classList.add('inline-block');
            }
            
       
           
        }
    },
}
</script>
<style scoped>
    
    .list-decimal li{
        margin-bottom: 10px;
    }
    .div-map{
        left: -6rem;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        position: relative;
        transform: skewX(5deg);
        
    }
    .chocando{
         animation: opacity .5s, zIndex 2s infinite;
    }
    .chocando-2{
         animation: opacity .5s, zIndex 4s infinite;
    }
    @keyframes zIndex {
        from {
            z-index: 0;
        }

        to {
            z-index: 10;
        }
    }    
    .div-map .div-img{ 
        top: 0;
        left: -3rem;
        right: -3rem;
        bottom: 0;
        position: absolute;
        transform: skewX(-5deg);
    }

    .div-map-menu{
        height: 100vh;
        margin-left: -60px;
        width: calc(100% + 7rem);
        overflow: hidden;
        position: relative;
        transform: skewX(5deg);
        
    }
    .div-menu{ 
        width: calc(100% + 7rem);
        left: -2rem;
        top: 0;
        position: absolute;
        transform: skewX(-5deg);
        
    }
    .div-menu .title{
        margin-left: -20px;
        width: calc(100% + 300px);
    }
    
    @media(max-width: 1024px){
        .div-map{
            left: 0;
            height:auto;
            overflow: auto;
            transform: skewX(0deg);
            display: block;
        }
        .div-map .div-img{ 
            left: 0;
            position: relative;
            transform: skewX(0deg);
        }
        .div-map-menu{
            height: 43rem;
            margin-left: 0;
            width: 100%;
            transform: skewX(0deg);
            
        }
        .div-menu{ 
            width: 100%;
            left: 0;
            top: 0;
            position: absolute;
            transform: skewX(0deg);
            
        }
    }
    @media(max-width: 600px){
        .bg-pin{
            font-size: 0.5rem !important;
            width: 80px !important;
            padding: 3px !important;
        }
        .div-map-menu{
            height: 35rem; 
            overflow-y: auto;
        }
    }
    @media(max-width:1200px){
        .div-map-menu{ 
            overflow-y: auto;
        }
    }
    .opacity{
        animation: opacity .5s;
    }
    @keyframes opacity {
        from {
            opacity: 0;
        }

        to {
             opacity: 1;
        }
    }    
    .pin{
        width: 7%;
        height: 7%;
        background-size: contain;
        background-position: center;
        position: absolute;
        /* display: inline-block;  mostrar tudo*/
        background-repeat: no-repeat;
    }
    .pin-escola{
        background-image: url(~@/assets/img/mapa/PIN_ESCOLAS.png);
    }
    .pin-shoping{
        background-image: url(~@/assets/img/mapa/PIN_SHOPPINGS.png);
    }
    .pin-pracas-parques, .pin-ponto-brt{
        background-image: url(~@/assets/img/mapa/PARQUES.png);
    }
    .pin-restaurantes-lanchonetes{
        background-image: url(~@/assets/img/mapa/PIN_RESTAURANTES.png);
    }
    .pin-alimentos-saudaveis{
        background-image: url(~@/assets/img/mapa/PIN_COMIDA_SAUDAVEL.png);
    }
    .pin-supermercados{
        background-image: url(~@/assets/img/mapa/PIN_MERCADOS.png);
    }
    .pin-saloes{
        background-image: url(~@/assets/img/mapa/PIN_SALAO_DE_BELEZA.png);
    }
    .pin-pet-shop{
        background-image: url(~@/assets/img/mapa/PIN_PET_SHOPS.png);
    }
    .pin-farmacia{
        background-image: url(~@/assets/img/mapa/PIN_FARMACIAS.png);
    }
    .pin-centros-medicos{
        background-image: url(~@/assets/img/mapa/PIN_HODPITAL.png);
    }
    .pin-bancos{
        background-image: url(~@/assets/img/mapa/PIN_BANCOS.png);
    }
    .bg-pin{
        background: rgba(255, 255, 255, 0.9);
        font-size: 90%;
        margin-left: 83%;
    }
    .pin-1{
        top: 1%;
        left: 1%;
        transform: translateX(423%) translateY(425%);
    }
    .pin-2{
        top: 1%;
        left: 1%;
        transform: translateX(690%) translateY(180%);
    }
    .pin-3{
        top: 1%;
        left: 1%;
        transform: translateX(595%) translateY(280%);
    }
    .pin-3-1{
        top: 1%;
        left: 1%;
        transform: translateX(540%) translateY(1100%);
    }
   
    .pin-4{
        top: 1%;
        left: 1%;
        transform: translateX(460%) translateY(485%);
    }
    
    .pin-5{
        top: 1%;
        left: 1%;
        transform: translateX(405%) translateY(595%);
    }
    
   
    .pin-6{
        top: 1%;
        left: 1%;
        transform: translateX(515%) translateY(630%);
       
    }
    .pin-7{
        top: 1%;
        left: 1%;
        transform: translateX(640%) translateY(900%);
    }
    .pin-8{
        top: 1%;
        left: 1%;
        transform: translateX(725%) translateY(1075%);
    }
    
    .pin-9{
        top: 1%;
        left: 1%;
        transform: translateX(250%) translateY(490%);
    }
    .pin-10{
        top: 1%;
        left: 1%;
        transform: translateX(369%) translateY(386%);
    }

    .pin-11{
        top: 1%;
        left: 1%;
        transform: translateX(515%) translateY(430%);
    }
    .pin-12{
        top: 1%;
        left: 1%;
        transform: translateX(848%) translateY(975%);
    }
    .pin-13{
        top: 1%;
        left: 1%;
        transform: translateX(650%) translateY(160%);
    }
    .pin-14{
        top: 1%;
        left: 1%;
        transform: translateX(445%) translateY(345%);
    }
    
    .pin-15{
        top: 1%;
        left: 1%;
        transform: translateX(454%) translateY(610%);
    }
    .pin-15-1{
        top: 1%;
        left: 1%;
        transform: translateX(200%) translateY(415%);
        z-index: 2;
    }
    .pin-15-2{
        top: 1%;
        left: 1%;
        transform: translateX(180%) translateY(455%);
        z-index: 2;
    }
    .pin-15-3{
        top: 1%;
        left: 1%;
        transform: translateX(185%) translateY(390%);
    }
    .pin-15-4{
        top: 1%;
        left: 1%;
        transform: translateX(690%) translateY(1140%);
    }
    .pin-15-5{
        top: 1%;
        left: 1%;
        transform: translateX(680%) translateY(1110%);
    }
    .pin-16{
        top: 1%;
        left: 1%;
        transform: translateX(234%) translateY(378%);
    }
    .pin-17{
        top: 1%;
        left: 1%;
        transform: translateX(225%) translateY(570%);
    }

    .pin-17-1{
        top: 1%;
        left: 1%;
        transform: translateX(395%) translateY(390%);
    }
    .pin-17-2{
        top: 1%;
        left: 1%;
        transform: translateX(240%) translateY(560%);

    }
    .pin-18{
        top: 1%;
        left: 1%;
        transform: translateX(600%) translateY(145%);
    }
    .pin-19{
        top: 1%;
        left: 1%;
        transform: translateX(370%) translateY(580%);
    }
    .pin-19-1{
        top: 1%;
        left: 1%;
        transform: translateX(480%) translateY(500%);
    }

    .pin-20{
        top: 1%;
        left: 1%;
        transform: translateX(690%) translateY(1010%);
    }
    .pin-20-1{
        top: 1%;
        left: 1%;
        transform: translateX(725%) translateY(1075%);
    }

    .pin-20-2{
        top: 1%;
        left: 1%;
        transform: translateX(460%) translateY(485%);
    } 
    .pin-21{
        top: 1%;
        left: 1%;
        transform: translateX(530%) translateY(330%);
    }

    .pin-22{
        top: 1%;
        left: 1%;
        transform: translateX(255%) translateY(400%);
    }
    .pin-23{
        top: 1%;
        left: 1%;
        transform: translateX(473%) translateY(490%);
    }
    .pin-24{
        top: 1%;
        left: 1%;
        transform: translateX(545%) translateY(710%);
    }
    .pin-25{
        top: 1%;
        left: 1%;
        transform: translateX(530%) translateY(370%);
    }
    .pin-26{
        top: 1%;
        left: 1%;
        transform: translateX(575%) translateY(460%);
    }

    .pin-27{
        top: 1%;
        left: 1%;
        transform: translateX(505%) translateY(420%);
    }
    .pin-28{
        top: 1%;
        left: 1%;
        transform: translateX(460%) translateY(500%);
    }
 
    @media(min-width: 1200px) and (max-width: 1800px){

        .pin-8{
            display: none;
        }
        .pin-20 .bg-pin{
            margin-top: -10%;
        }
        .pin-20-1{
            display: none;
        }
        .pin-3-1{
            display: none;
        }
        .pin-15-5{
            display: none;
        }
        .pin-15-4{
            display: none;
        }

    }
    @media(min-width: 1200px){
         .pin-5 .bg-pin{
            left: -205%;
            margin-left: 0;
        }
        
        .pin-10 .bg-pin{
            left: -170%;
            margin-left: 0;
        }
    }
    @media(min-width: 1800px){
         .pin-5 .bg-pin{
            left: -150%;
            margin-left: 0;
        }
        .pin-10 .bg-pin{
            left: -120%;
            margin-left: 0;
        }
    }
</style>