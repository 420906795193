<template>
  <div class="w-screen h-screen bg fixed flex justify-center items-center z-100">
    <div id="defaultModal" aria-hidden="true" class="z-50 justify-center items-center md:inset-0">
      <div class="relative px-4 w-screen md:w-full max-w-2xl">
          <!-- Modal content -->
          <div class="relative rounded-xl shadow-lg modal-content">
              <!-- Modal header -->
              <div class="flex justify-between items-start p-5 rounded-t border-b relative">
                  <h3 class="text-2xl font-semibold text-white lg:text-3xl my-auto">
                      {{ acao }}
                  </h3>
                  
                  <button type="button" class="text-gray-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center lg:absolute close" title="Fechar" @click="$emit('openModal', '')">
                      <svg class="w-10 h-10" fill="#ffff" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>  
                  </button>
              </div>
              <!-- Modal body -->
              <div class="p-6 space-y-6 h-96 overflow-hidden w-full lg:w-96 body-modal relative">
                <form @submit="submitForm" class="form-cadastro">
                   
                    <input type="hidden" name="identificador" :value="`move-itaigara-${acao.replace(/ /g, '-').toLowerCase()}`">
                    
                    <div class="relative z-0 mb-6 w-full group">
                        <input type="text" name="nome" class="block text-2xl py-2.5 px-0 w-full text-white bg-transparent border-0 border-b-2 border-white appearance-none focus:outline-none focus:ring-0 peer" placeholder=" " required />
                        <label for="nome" class="absolute text-2xl text-white duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Nome</label>
                    </div>

                    <div class="relative z-0 mb-6 w-full group">
                        <input type="email" name="email_lead" class="block text-2xl py-2.5 px-0 w-full text-white bg-transparent border-0 border-b-2 border-white appearance-none  focus:outline-none focus:ring-0 peer" placeholder=" " required />
                        <label for="email" class="absolute text-2xl text-white duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">E-mail</label>
                    </div>

                    <div class="relative z-0 mb-6 w-full group">
                        <input type="tel" name="Telefone" class="block text-2xl py-2.5 px-0 w-full text-white bg-transparent border-0 border-b-2 border-white appearance-none focus:outline-none focus:ring-0 peer" placeholder=" " required />
                        <label for="telefone" class="absolute text-2xl text-white duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Telefone</label>
                    </div>
                    <p class="text-white">Ao informar meus dados eu concordo com a <a href="https://www.sertenge.com.br/politica-de-privacidade/" target="_blank" class="underline">Politica de Privacidade</a></p>
                    <button class="mb-6 float-right text-white py-1 px-2 border-2 border-white transition-all  duration-300 hover:scale-110">
                         Enviar 
                    </button>
                </form>
                <div class="logo-moove flex justify-center absolute bottom-5">
                    <img src="@/assets/img/logo-move-itaigara.png" alt="" class=" h-10 object-fill">
                </div> 
              </div>
          </div>
      </div>
  </div>
</div>
</template>
<script>
export default {
    name: 'FormComponent',
    props: ['acao'],
    emits: ['openModal'],
    methods: {
        submitForm(e){
            e.preventDefault();
            const data = new FormData(e.target);
            var utm_source = 'direct';
            var utm_medium = 'direct';
            var utm_campaign = 'direct';
            
            if(this.$route.query != undefined){

                if(this.$route.query.utm_source != undefined){
                    utm_source = this.$route.query.utm_source;
                }
                if(this.$route.query.utm_medium != undefined){
                    utm_medium = this.$route.query.utm_medium; 
                }
                if(this.$route.query.utm_campaign != undefined){
                    utm_campaign = this.$route.query.utm_campaign; 
                }
        
            }

            data.set('communications', true);
            data.set('token_rdstation', '11d0f7e6b61561470ce3328a3f2fffc9');
            data.set('traffic_source', utm_source);
            data.set('traffic_medium', utm_medium);
            data.set('traffic_campaign', utm_campaign);
    

            fetch(`https://www.rdstation.com.br/api/1.3/conversions`, {
                method: 'POST',
                body: data
            }).then(response => response.text())
            .then(res => {

                // var event_id = new Date().getTime()+"."+Math.random();
    
                // window.fbq('trackCustom', 'cadastro', {}, {eventID: event_id});

                // var url = window.location.href;
                // var params = 'url='+url+'&userAgent='+window.navigator.userAgent+'&eventId='+event_id;
                // var xhttp = new XMLHttpRequest();

                // xhttp.open("POST", "https://acheiinterativa.com.br/integracao/capi/sertenge/pageview.php", true);
                // xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
                // xhttp.send(params);

                // console.log( 'Cadastro:', event_id);


                if(this.acao == 'Baixar Book'){
                    window.location.href=`./agradecimento/${this.acao}/?utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}`;
                    
                }else{
                    window.location.href=`./agradecimento/${this.acao}/?utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}`;
                }
                
            })
        }
    },
}
</script>
<style scoped>
  .bg{
    background: rgba(0, 0, 0, 0.4);
  }
  .z-100{
      z-index: 100;
  }
  @media (min-width: 1024px) { 
        .close{
            right: -50px;
            top: -50px;
            transition: all .5s;
        }
    }
  
  .close:hover{
      transform: rotate(90deg);
  }
    .body-modal::after{
        content: '';
        width: 5rem;
        height: 2rem;
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: white;
        transform: skewY(-63deg);
    }
    .modal-content{
        background: rgba(251, 146, 60, 0.6);
        backdrop-filter: blur( 4px );
        -webkit-backdrop-filter: blur( 4px );
    }
</style>

