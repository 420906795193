<template lang="">
    <div class="carousel-perspectivas px-14 xl:px-28" id="carousel-perspectivas">
        <div class="w-full">
            <h2 class="uppercase text-orange-400 text-3xl text-center">viva sempre em movimento</h2>
            <h2 class="uppercase text-orange-400 text-3xl font-semibold text-center mb-5">DÊ MAIS VIDA AO SEU TEMPO</h2>
            <p class="text-gray-700 text-xl text-center">O <strong> Move </strong> oferece a você uma excelente </p>
            <p class="text-gray-700 text-xl text-center mb-10">infraestrutura de lazer, esporte, trabalho e convivência.</p>
        </div>
        
        <div class="space-y-4 md:space-y-0 md:grid md:grid-cols-2 md:gap-2 xl:grid-cols-4 mt-3 mt-lg-4 xl:gap-3 mb-10">
            
            <div class="portfolio type1 p-0" v-for="(img, index) in images" :key="index">
              <figure>
                  <img :src="img.src">		
                  <div class="image-overlay">
                      <div class="links"><a :href="img.alta" class="glightboxComum" :data-glightbox="`title:${img.titulo};description: ${img.descricao}`"><span class="icon icon-linked"> </span></a></div>
                      <div class="image-overlay-details">
                          <h4 class="text-white text-sm lg:text-xl uppercase" style="margin-bottom: 0;">{{ img.titulo }}</h4>
                          <p class="categories text-xs text-white">{{ img.descricao }}</p>
                      </div>
                  </div>
              </figure>
              <img v-if="index == 3" src="@/assets/img/areas-comuns/Move-Itaigara-Piscina-adulto-com-deck-e-piscina-infantil.jpg" class="glightboxComum" style="display:none !important;" :data-glightbox="`title:${img.titulo};description: PISCINA ADULTO COM DECK MOLHADO E PISCINA INFANTIL;`">
            </div>

            

        </div> 
        <div class="w-full">
            <p class="text-gray-700 text-xl text-center">Além de tanta opção, o empreendimento fica ao lado de uma praça de 7.000m², </p>
            <p class="text-gray-700 text-xl text-center mb-8">com parque e campo society. Tudo para você viver movido a bons momentos. </p>
            <div class="flex flex-col justify-center">
                <div @click="$emit('openModal', 'Falar com Corretor')" class="btn px-14 py-2 uppercase bg-[#6C6749] skew inline-block mx-auto cursor-pointer"><div class="skew-text text-white">Falar com Corretor</div></div>
                <!-- <p class="text-gray-700 text-xl text-center mt-4">A partir de R$ 698 mil</p> -->
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'CarouselComunsComponent',
    emits: ['openModal'],
    data() {
        return {
            images:[
                {src: require('@/assets/img/areas-comuns/Move-Itaigara-Fachada.jpg') , alta: require('@/assets/img/areas-comuns/Move-Itaigara-Fachada-full.jpg'), titulo: 'Fachada', descricao: 'Fachada Move Itaigara'},
                {src: require('@/assets/img/areas-comuns/Move-Itaigara-Localizacao.jpg') , alta: require('@/assets/img/areas-comuns/Move-Itaigara-Localizacao-full.jpg'), titulo: 'LOCALIZAÇÃO ', descricao: ' 2 TORRES COM 12 PAVIMENTOS BEM PERTINHO DO COLÉGIO GIRASSOL'},
                {src: require('@/assets/img/areas-comuns/Move-Itaigara-Areas-comuns.jpg') , alta: require('@/assets/img/areas-comuns/Move-Itaigara-Areas-comuns-full.jpg'), titulo: 'ÁREAS COMUNS', descricao: 'COM AMBIENTES MODERNOS E EQUIPADOS'},
                {src: require('@/assets/img/areas-comuns/Move-Itaigara-Piscina-raia-20m.jpg') , alta: require('@/assets/img/areas-comuns/Move-Itaigara-Piscina-raia-20m-full.jpg'), titulo: 'PISCINA COM RAIA', descricao: 'PISCINA COM RAIA DE 20M'},
                {src: require('@/assets/img/areas-comuns/Move-Itaigara-Piscinas-e-quadra-esportiva.jpg') , alta: require('@/assets/img/areas-comuns/Move-Itaigara-Piscinas-e-quadra-esportiva-full.jpg'), titulo: '03 PISCINAS E UMA QUADRA ESPORTIVA', descricao: 'VOCÊ SE DIVERTE E MANTÉM A FORMA AO MESMO TEMPO'},
                {src: require('@/assets/img/areas-comuns/Move-Itaigara-Sport-bar.jpg') , alta: require('@/assets/img/areas-comuns/Move-Itaigara-Sport-bar-full.jpg'), titulo: 'SPORT BAR', descricao: 'ESPAÇOS SOB MEDIDA PARA SE DIVERTIR'},
                {src: require('@/assets/img/areas-comuns/Move-Itaigara-Salao-de-festas.jpg') , alta: require('@/assets/img/areas-comuns/Move-Itaigara-Salao-de-festas-full.jpg'), titulo: 'SALÃO DE FESTAS COM ESPAÇO GOURMET', descricao: 'MAIS DO QUE COMEMORAR, CELEBRAR A VIDA E SUAS CONQUISTAS'},
                {src: require('@/assets/img/areas-comuns/Move-Itaigara-Brinquedoteca.jpg') , alta: require('@/assets/img/areas-comuns/Move-Itaigara-Brinquedoteca-full.jpg'), titulo: 'BRINQUEDOTECA', descricao: 'ESPAÇOS PROJETADOS PARA A CRIANÇADA SE SENTIR À VONTADE'},
                {src: require('@/assets/img/areas-comuns/Move-Itaigara-Parque-infantil.jpg') , alta: require('@/assets/img/areas-comuns/Move-Itaigara-Parque-infantil-full.jpg'), titulo: 'PARQUE INFANTIL', descricao: 'ESTRUTURA COMPLETA.'},
                {src: require('@/assets/img/areas-comuns/Move-Itaigara-Bicicletario.jpg') , alta: require('@/assets/img/areas-comuns/Move-Itaigara-Bicicletario-full.jpg'), titulo: 'BICICLETÁRIO', descricao: 'PARA QUEM NÃO ABRE MÃO DO PASSEIO COM A BIKE'},
                {src: require('@/assets/img/areas-comuns/Move-Itaigara-Salao-de-jogos.jpg') , alta: require('@/assets/img/areas-comuns/Move-Itaigara-Salao-de-jogos-full.jpg'), titulo: 'SALÃO DE JOGOS', descricao: 'ESPAÇO SOB MEDIDA PARA SE DIVERTIR.'},
                {src: require("@/assets/img/areas-comuns/Move-Itaigara-Coworking.jpg"), alta: require("@/assets/img/areas-comuns/Move-Itaigara-Coworking-full.jpg"), titulo: "COWORKING", descricao: 'PREPARADA PARA VIDEOCONFERÊNCIAS E SALA DE ESTUDO'},
                {src: require("@/assets/img/areas-comuns/Move-Itaigara-lobby.jpg"), alta: require("@/assets/img/areas-comuns/Move-Itaigara-lobby-full.jpg"), titulo: "LOBBY", descricao: 'ACESSO AOS APARTAMENTOS'},
                {src: require('@/assets/img/areas-comuns/Move-Itaigara-Espaco-externo.jpg') , alta: require('@/assets/img/areas-comuns/Move-Itaigara-Espaco-externo-full.jpg'), titulo: 'Espaço Externo', descricao: 'SUA VIDA NO SEU RITMO'},
                {src: require('@/assets/img/areas-comuns/Move-Itaigara-Espaco-funcional-e-academia.jpg') , alta: require('@/assets/img/areas-comuns/Move-Itaigara-Espaco-funcional-e-academia-full.jpg'), titulo: 'FITNESS', descricao: 'ESPAÇO FUNCIONAL E ACADEMIA'},
                {src: require('@/assets/img/areas-comuns/Move-Itaigara-Crossfit.jpg') , alta: require('@/assets/img/areas-comuns/Move-Itaigara-Crossfit-full.jpg'), titulo: 'CROSSFIT', descricao: 'VIVA SEMPRE EM MOVIMENTO. APROVEITE CADA SEGUNDO'},
                {src: require('@/assets/img/areas-comuns/Move-Itaigara-Espaco-saude.jpg') , alta: require('@/assets/img/areas-comuns/Move-Itaigara-Espaco-saude-full.jpg'), titulo: 'Espaço Saúde', descricao: 'ESPAÇO PARA PRATICA DE YOGA, MEDITAÇÃO, PILATES ENTRE OUTROS'},
                {src: require('@/assets/img/areas-comuns/Move-Itaigara-Escada-fitness.jpg') , alta: require('@/assets/img/areas-comuns/Move-Itaigara-Escada-fitness-full.jpg'), titulo: 'ESCADA FITNESS', descricao: 'SUA MALHAÇÃO ACABA DE GANHAR UM UPGRADE'},
                
            ]
        }
    },
    mounted() {
        GLightbox({selector: '.glightboxComum'});
    }
}
</script>
<style scoped>
 
@charset "utf-8";
.skew{
    transform: skew(-40deg);
    -webkit-transform: skew(-40deg);
    -moz-transform: skew(-40deg);
    -o-transform: skew(-40deg);
}
.skew-text{
    transform: skew(40deg);
    -webkit-transform: skew(40deg);
    -moz-transform: skew(40deg);
    -o-transform: skew(40deg);
}

.skew-text{
    text-align: center;
}
/*----*****---- << 1.2. Headings >> ----*****----*/
.portfolio .image-overlay, .recent-portfolio-widget ul li a:before, .dt-sc-image-caption.type2:hover .dt-sc-image-content {
    background: rgba(251, 146, 60, 0.9) ;
}


/*----*****---- << 1.3. Transition >> ----*****----*/


.portfolio .image-overlay .links a:first-child, .portfolio .image-overlay-details, .dt-sc-team.hide-social-show-on-hover .dt-sc-team-social, .portfolio .image-overlay .links { -webkit-transition:all 0.6s ease; -moz-transition:all 0.6s ease; -o-transition:all 0.6s ease; -ms-transition:all 0.6s ease; transition:all 0.6s ease; }
.portfolio .image-overlay .links a:last-child, .portfolio figure img, .dt-sc-image-caption .dt-sc-image-wrapper img, .dt-sc-menu figure img { -webkit-transition:all 0.9s ease; -moz-transition:all 0.9s ease; -o-transition:all 0.9s ease; -ms-transition:all 0.9s ease; transition:all 0.9s ease; }
.portfolio .image-overlay .links:after { -webkit-transition:all 0.4s ease-out; -moz-transition:all 0.4s ease-out; -o-transition:all 0.4s ease-out; -ms-transition:all 0.4s ease-out; transition:all 0.4s ease-out; }

.portfolio figure img { -webkit-transition:all 1.5s ease; -moz-transition:all 1.5s ease; -o-transition:all 1.5s ease; -ms-transition:all 1.5s ease; transition:all 1.5s ease; }

img { max-width:100%; height:auto; }

.portfolio.column { margin-bottom:25px; }
.portfolio.column.no-space { margin-bottom:0px; }

.portfolio figure { position:relative; overflow:hidden; }
.portfolio:hover figure img { -webkit-transform: scale(1.2); -moz-transform: scale(1.2); -ms-transform: scale(1.2); transform: scale(1.2); }

.portfolio .image-overlay { position:absolute; opacity:0; display:block; bottom:0; left:0; right:0; width:100%; height:100%; overflow:hidden; -webkit-transition:all 0.5s; -moz-transition:all 0.5s; -ms-transition:all 0.5s; -o-transition:all 0.5s; transition:all 0.5s; }

.portfolio .image-overlay .links { position:absolute; left:0px; top:0px; width:100%; height:100%; }
.portfolio .image-overlay .links a { display:inline-block; height:45px; width:45px; font-size:20px; line-height:50px;  border-radius:50%; margin:30px 0px 0px 30px; position:relative; bottom:100%; z-index: 1; text-align:center; }
.portfolio .image-overlay .links a:last-child { margin-left:5px; }
.portfolio .image-overlay .links a span { line-height:45px; }

.portfolio .image-overlay .links { width:100%; height:100%; }
.portfolio .image-overlay .links:after { content:''; position:absolute; z-index:-1; }
.portfolio .image-overlay .links:before { left:auto; left:10px;  z-index:2; }
.portfolio .image-overlay .links:after {  width:0;  height:200%; z-index:1;  left:0px;  top:-76%;  margin:-5px 0 0 -5px;  -webkit-transform-origin:0 0;  -webkit-transform:rotate(20deg);  -moz-transform-origin:0 0;  -moz-transform:rotate(30deg);  -ms-transform-origin:0 0;  -ms-transform:rotate(30deg);  transform-origin:0 0; transform:rotate(30deg); z-index:0; }
.portfolio:hover .image-overlay .links:after { width:70%; }

@media screen and (-webkit-min-device-pixel-ratio:0) {
    /* Safari */
    ::i-block-chrome,.portfolio .image-overlay .links:after {  -webkit-transform-origin:0 0;  -webkit-transform:rotate(30deg);  -moz-transform-origin:0 0;  -moz-transform:rotate(30deg);  -ms-transform-origin:0 0;  -ms-transform:rotate(30deg);  transform-origin:0 0; transform:rotate(30deg); }
}

.portfolio:hover .image-overlay .links a { bottom:0px; }

.portfolio .image-overlay-details { position:absolute; right:30px; bottom:-100%; text-align:right; border-right:1px solid; box-sizing:border-box; padding:0px 20px; }
.portfolio .image-overlay-details .categories { margin-bottom:0px; }
.portfolio .image-overlay-details .categories a { letter-spacing:0.5px; }
.portfolio .image-overlay h2 { font-weight:300; margin-bottom:0px; padding:0px; }

.portfolio:hover .image-overlay-details { bottom:30px; }

.portfolio:hover .image-overlay { opacity:1; }

.isotope-hidden, .hidden { display:none !important; }

.isotope,
.isotope .isotope-item {
    -webkit-transition-duration: 0.8s;
        -moz-transition-duration: 0.8s;
        -ms-transition-duration: 0.8s;
        -o-transition-duration: 0.8s;
            transition-duration: 0.8s;
}

.isotope {
    -webkit-transition-property: height, width;
        -moz-transition-property: height, width;
        -ms-transition-property: height, width;
        -o-transition-property: height, width;
            transition-property: height, width;
}

.isotope .isotope-item {
    -webkit-transition-property: -webkit-transform, opacity;
        -moz-transition-property:    -moz-transform, opacity;
        -ms-transition-property:     -ms-transform, opacity;
        -o-transition-property:         top, left, opacity;
            transition-property:         transform, opacity;
}

.isotope.no-transition,
.isotope.no-transition .isotope-item,
.isotope .isotope-item.no-transition {
    -webkit-transition-duration: 0s;
        -moz-transition-duration: 0s;
        -ms-transition-duration: 0s;
        -o-transition-duration: 0s;
            transition-duration: 0s;
}

/* Architecture */
.portfolio.type2 .image-overlay { background:rgba(255, 255, 255, 0.7); }
.portfolio.type2 .image-overlay-details { left:0px; bottom:-100px; right:inherit; width:100%; text-align:left; padding:20px 20px 15px; box-sizing:border-box; border:0px; }
.portfolio.type2:hover .image-overlay-details { bottom:0px; }
.portfolio.type2 .image-overlay h2 { font-size:18px; text-transform:uppercase; font-weight:600; }
.portfolio.type2 .image-overlay h2 a, .portfolio.type2 .image-overlay-details .categories a { color:#ffffff; }

.portfolio.type2 .image-overlay .links:after { background:none; }
.portfolio.type2 .image-overlay .links { left:inherit; left:100%; width:100%; height:auto; bottom:57px; top:inherit; }
.portfolio.type2 .image-overlay .links a { background:#ffff; border-radius:0px; border:none;  top:auto; bottom:auto; right:0px; float:right; width:60px; height:60px; margin:0px 0px 1px 1px; }
.portfolio.type2 .image-overlay .links a span { font-size:28px; line-height:60px; }
.portfolio.type2 .image-overlay .links a:hover { color:#ffffff; }
.portfolio.type2:hover .image-overlay .links { left:0px; }

.dt-sc-portfolio-sorting.type2 a, .dt-sc-portfolio-sorting.type2 a:hover, .dt-sc-portfolio-sorting.type2 a.active-sort { border:none; background:none; margin:0px; }
.dt-sc-portfolio-sorting.type2 a:before, .dt-sc-portfolio-sorting.type2 a:hover:before, .dt-sc-portfolio-sorting.type2 a.active-sort:before { background:none; }
.dt-sc-portfolio-sorting.type2 a:after {content:""; position:absolute; top:0px; bottom:0px; margin:auto; width:1px; height:15px; left:-3px; background:#ffffff; -webkit-transform:rotate(45deg); -moz-transform:rotate(45deg); -ms-transform:rotate(45deg);  transform:rotate(45deg); }

.dt-sc-portfolio-sorting.type2 { clear:none; width:auto; margin:0px 0px 0px 15px; padding:0px 20px; position:relative; }
.page-template-tpl-portfolio .dt-sc-portfolio-sorting.type2 { margin:0px 0px 60px; }
.dt-sc-portfolio-sorting.type2 a { color:#ffffff; padding:25px 20px 23px; }
.dt-sc-portfolio-sorting.type2 a:hover, .dt-sc-portfolio-sorting.type2 a.active-sort { color:rgba(255, 255, 255, 0.4) !important; }
.dt-sc-portfolio-sorting.type2 a:after, .dt-sc-portfolio-sorting.type2 a:hover:after { background:#ffffff; }
.dt-sc-portfolio-sorting.type2 a:first-child:hover:before, .dt-sc-portfolio-sorting.type2 a:first-child:hover:after { background:none; }
.dt-sc-portfolio-sorting.type2 a.active-sort:before { content:""; position:absolute; top:-2px; left:0px; width:100%; height:100%; border-top:1px solid #ffff; border-bottom:1px solid #ffff;  height:-webkit-calc(100% + 2px); height:-moz-calc(100% + 2px); height:calc(100% + 2px); }
@media screen and (-webkit-min-device-pixel-ratio:0) {
    /* Safari */
    ::i-block-chrome,.dt-sc-portfolio-sorting.type2 a.active-sort:before { height:103%; }
}
.dt-sc-portfolio-sorting.type2:before { content:""; position:absolute; right:-25px; top:0px; width:50px; height:100%; -webkit-transform:skewX(-30deg); -moz-transform:skewX(-30deg); -ms-transform:skewX(-30deg);  transform:skewX(-30deg);  }
.dt-sc-portfolio-sorting.type2 a.active-sort:after { background:#ffffff; }
.dt-sc-portfolio-sorting.type2 a:first-child:after, .dt-sc-portfolio-sorting.type2 a.active-sort:first-child:after { background:none; }

/* Fitness */
.dt-sc-portfolio-container .portfolio.type3 { padding:2px; }
.column .portfolio.type3 { position:relative; }
.portfolio.type3 .image-overlay .links:after { background:none; }

.portfolio.type3 .image-overlay { background:rgba(0, 0, 0, 0.6); box-sizing:border-box; left:10px; top:10px; width:89.1%; height:89%; width:-webkit-calc(100% - 20px); width:-moz-calc(100% - 20px); width:calc(100% - 20px); height:-webkit-calc(100% - 20px); height:-moz-calc(100% - 20px); height:calc(100% - 20px); }
/*.portfolio.type3:before { content:""; position:absolute; left:12px; top:12px; border:10px solid rgba(255, 255, 255, 0.7); box-sizing:border-box; width:93.5%; height:93.5%; width:-webkit-calc(100% - 24px); width:-moz-calc(100% - 24px); width:calc(100% - 24px); height:-webkit-calc(100% - 24px); height:-moz-calc(100% - 24px); height:calc(100% - 24px); z-index:1; -webkit-transition:all 0.3s linear; -moz-transition:all 0.3s linear; -o-transition:all 0.3s linear; -ms-transition:all 0.3s linear; transition:all 0.3s linear; opacity:0; }*/
.portfolio.type3:hover:before { opacity:1; }

.portfolio.type3 .image-overlay .links { top:0px; bottom:0px; left:0px; right:0px; margin:auto; width:100%; height:17px; text-align:center; }
.portfolio.type3 .image-overlay .links a { font-size:16px; line-height:24px; font-weight:bold; text-transform:uppercase; letter-spacing:1px; border:none; background:none; width:auto; margin:0px; padding:0px 10px; }
.portfolio.type3 .image-overlay .links a:first-child { margin:0px; }
.portfolio.type3 .image-overlay .links a:hover { background:none; }
.portfolio.type3:hover .image-overlay .links a { bottom:14px; }


@media screen and (-webkit-min-device-pixel-ratio:0) {
    /* Safari */
    ::i-block-chrome,.portfolio-single-grid .dt-sc-two-fifth .portfolio.type3 .image-overlay, ::i-block-chrome,.portfolio-single-grid .dt-sc-two-third .portfolio.type3 .image-overlay { width:94.5%; height:89.5%; }
    ::i-block-chrome,.portfolio-single-grid .dt-sc-two-fifth .portfolio.type3:before, ::i-block-chrome,.portfolio-single-grid .dt-sc-two-third .portfolio.type3:before { width:96.7%; height:94%; }
    ::i-block-chrome,.portfolio-single-grid .dt-sc-one-fifth #dt_portfolios-153.portfolio.type3 .image-overlay { height:94.6%; }
    ::i-block-chrome,.portfolio-single-grid .dt-sc-one-fifth #dt_portfolios-153.portfolio.type3:before { height:96.8%; }

/*		::i-block-chrome,.dt-sc-portfolio-container .portfolio.type3:nth-child(2) .image-overlay { height:95%; }
    ::i-block-chrome,.dt-sc-portfolio-container .portfolio.type3:nth-child(2):before { height:97%; }*/
}

/* Hotel */
.portfolio.type4 .image-overlay { background:rgba(254, 203, 22, 0.8); }
.portfolio.type4 .image-overlay .links:after { background:none; }
.portfolio.type4 .image-overlay .links { left:0px; right:0px; top:0px; bottom:0px; margin:auto; width:111px; height:45px; }
.portfolio.type4 .image-overlay .links a { margin:0px 5px; bottom:100%; background:#ffff; border:0px; border-radius:0px; }
.portfolio.type4 .image-overlay .links a:hover { color:#ffff; }

.portfolio.type4 .image-overlay .links a:first-child { right:100%; bottom:0px; }
.portfolio.type4:hover .image-overlay .links a:first-child { right:0px; bottom:0; }

.portfolio.type4 .image-overlay .links a:last-child { left:100%; bottom:0px; margin-left:0px; }
.portfolio.type4:hover .image-overlay .links a:last-child { left:0px; bottom:0; }
.portfolio.type4 .image-overlay .links a:last-child { -webkit-transition:all 0.6s ease; -moz-transition:all 0.6s ease; -o-transition:all 0.6s ease; -ms-transition:all 0.6s ease; transition:all 0.6s ease; }

/* Medical */
.portfolio.type5 .image-overlay-details { left:0px; bottom:-100px; right:inherit; width:100%; background:rgba(255, 255, 255, 0.1); text-align:center; padding:20px 10px; box-sizing:border-box; border:0px; }
.portfolio.type5:hover .image-overlay-details { bottom:0px; }
.portfolio.type5 .image-overlay h2 { font-size:16px; margin-bottom:5px; }
.portfolio.type5 .image-overlay .links:after { background:none; }
.portfolio.type5 .image-overlay .links { left:0px; right:0px; top:0px; bottom:0px; margin:auto; width:120px; height:45px; }
.portfolio.type5 .image-overlay .links a { margin:0px 5px; bottom:100%; }

.portfolio.type5 .image-overlay .links a:first-child { right:100%; bottom:0px; }
.portfolio.type5:hover .image-overlay .links a:first-child { right:0px; bottom:90%; }

.portfolio.type5 .image-overlay .links a:last-child { left:100%; bottom:0px; }
.portfolio.type5:hover .image-overlay .links a:last-child { left:0px; bottom:90%; }
.portfolio.type5 .image-overlay .links a:last-child { -webkit-transition:all 0.6s ease; -moz-transition:all 0.6s ease; -o-transition:all 0.6s ease; -ms-transition:all 0.6s ease; transition:all 0.6s ease; }

.dt-sc-portfolio-sorting.type5 a { border-color:#eeeeee; border-radius:5px; }

/* Photography */
.portfolio.type6.column { margin-bottom:35px; }
.portfolio.type6 figure { overflow:visible; }
.portfolio.type6 figure img { filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); filter:grayscale(100%); -webkit-filter:grayscale(1); filter:gray; }
.portfolio.type6:hover figure img { filter:grayscale(0%); -webkit-filter:grayscale(0); filter:none; }

.portfolio.type6 .image-overlay { background:none; left:-10px; bottom:-10px; width:103%; height:100%; width:-webkit-calc(100% + 10px); width:-moz-calc(100% + 10px); width:calc(100% + 10px); height:-webkit-calc(100% + 10px); height:-moz-calc(100% + 10px); height:calc(100% + 10px); }
.portfolio.type6 .image-overlay:before {
    content:""; position:absolute; left:10px; bottom:10px; width:100%; height:100%;
    background:-moz-linear-gradient(top,  rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%); /* FF3.6+ */
    background:-webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0, 0, 0,0)), color-stop(100%,rgba(0, 0, 0, 0.7))); /* Chrome,Safari4+ */
    background:-webkit-linear-gradient(top,  rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0.7) 100%); /* Chrome10+,Safari5.1+ */
    background:-o-linear-gradient(top,  rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0.7) 100%); /* Opera 11.10+ */
    background:-ms-linear-gradient(top,  rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0.7) 100%); /* IE10+ */
    background:linear-gradient(to bottom,  rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0.7) 100%); /* W3C */
    filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
}
.portfolio.type6 .image-overlay .links:after { background:none; }
.portfolio.type6 .image-overlay .links a { background:#000000; border:none; border-radius:0px; position:absolute; width:60px; height:60px; display:inline-block; line-height:66px; font-size:24px; margin:0px; z-index:1; }
.portfolio.type6 .image-overlay .links a span { line-height:60px; }
.portfolio.type6 .image-overlay .links a:last-child { margin-left:60px; }
.portfolio.type6 .image-overlay .links { bottom:0px; left:0px; }
.portfolio.type6 .image-overlay .links a { bottom:-100%; left:0px; }
.portfolio.type6:hover .image-overlay .links a { bottom:0px; }
.portfolio.type6:hover figure img { -webkit-transform: scale(1); -moz-transform: scale(1); -ms-transform: scale(1); transform: scale(1); }

.portfolio.type6 .image-overlay .links a:hover { color:#ffffff; }

.dt-sc-portfolio-sorting.type6 a { background:#000000; color:#ffffff; }
.dt-sc-portfolio-sorting.type6 a:before { content:""; position:absolute; left:0px; top:0px; width:100%; height:100%; z-index:-1; opacity:0; }
.dt-sc-portfolio-sorting.type6 a.active-sort:before { left:-7px; top:-7px; background:#000000; opacity:1; }

.isotope .portfolio.type6 .image-overlay { width:100%; height:100%; left:0px; bottom:0px; }
.isotope .portfolio.type6 .image-overlay:before { left:0px; bottom:0px; }

.portfolio.type6.column.no-space { margin-bottom:0px; }

/* Restaurant */
.portfolio.type7 .image-overlay .links:after { background:none; }
.portfolio.type7 .image-overlay-details { width:100%; text-align:center; border:none; }
.portfolio.type7 .image-overlay h2 { font-size:14px; font-weight:bold; text-transform:uppercase; }

.portfolio.type7 .image-overlay-details .categories { margin-bottom:10px; }
.portfolio.type7 .image-overlay-details .categories a { position:relative; padding:0px 15px 0px 17px; font-size:12px; text-transform:uppercase; }
.portfolio.type7 .image-overlay-details .categories a:before { content:""; position:absolute; width:8px; height:8px; left:0px; top:4px; -moz-transform:rotate(45deg); -webkit-transform:rotate(45deg); transform:rotate(45deg); }
.portfolio.type7 .image-overlay-details .categories a.nonveg:before { background:#bb0605; }
.portfolio.type7 .image-overlay-details .categories a.veg:before { background:#328500; }

.portfolio.type7 .image-overlay-details { right:0px; }
.portfolio.type7:hover .image-overlay-details { bottom:70px; }

.portfolio.type7 .image-overlay  {
    background:-moz-linear-gradient(top,  rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%); /* FF3.6+ */
    background:-webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0, 0, 0,0)), color-stop(100%,rgba(0, 0, 0, 0.8))); /* Chrome,Safari4+ */
    background:-webkit-linear-gradient(top,  rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0.8) 100%); /* Chrome10+,Safari5.1+ */
    background:-o-linear-gradient(top,  rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0.8) 100%); /* Opera 11.10+ */
    background:-ms-linear-gradient(top,  rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0.8) 100%); /* IE10+ */
    background:linear-gradient(to bottom,  rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0.8) 100%); /* W3C */
    filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}

.portfolio.type7 .image-overlay .links:after { background:none; }
.portfolio.type7 .image-overlay .links { left:0px; right:0px; width:100%; height:auto; bottom:0px; top:inherit; width:180px; margin:auto; }
.portfolio.type7:hover .image-overlay .links { left:0px; }
.portfolio.type7 .image-overlay .links a { margin-left:0px; border:none; width:87px; border-radius:0px; line-height:60px; }
.portfolio.type7 .image-overlay .links a span { line-height:60px; }
.portfolio.type7 .image-overlay .links a:hover { background:none; color:#ffffff; z-index:1; }
.portfolio.type7 .image-overlay .links a:before { content:""; position:absolute; left:12px; bottom:-32px; width:60px; height:60px; border:2px solid; -moz-transform:rotate(45deg); -webkit-transform:rotate(45deg); transform:rotate(45deg); -ms-transform:rotate(45deg); z-index:-1; }

.portfolio.type7 .image-overlay .links a, .portfolio.type7:hover .image-overlay .links a { bottom:0px; }
.portfolio.type7 .image-overlay .links { bottom:-100%; }
.portfolio.type7:hover .image-overlay .links { bottom:0px; }
.portfolio.type7 .image-overlay .links a:first-child, .portfolio.type7 .image-overlay .links a:last-child { -webkit-transition:all 0.5s ease; -moz-transition:all 0.5s ease; -o-transition:all 0.5s ease; -ms-transition:all 0.5s ease; transition:all 0.5s ease; }

.dt-sc-portfolio-sorting.type7 a { border-width:2px; border-radius:30px; }

/* Spa */
.dt-sc-portfolio-container .portfolio.type8 figure img, .dt-sc-portfolio-container .portfolio.type8 figure .image-overlay { border-radius:0px 40px 0px 40px; }
.dt-sc-portfolio-container .portfolio.type8:nth-child(1) figure img, .dt-sc-portfolio-container .portfolio.type8:nth-child(3) figure img, .dt-sc-portfolio-container .portfolio.type8:nth-child(6) figure img, .dt-sc-portfolio-container .portfolio.type8:nth-child(8) figure img, .dt-sc-portfolio-container .portfolio.type8:nth-child(1) figure .image-overlay, .dt-sc-portfolio-container .portfolio.type8:nth-child(3) figure .image-overlay, .dt-sc-portfolio-container .portfolio.type8:nth-child(6) figure .image-overlay, .dt-sc-portfolio-container .portfolio.type8:nth-child(8) figure .image-overlay { border-radius:40px 0px 40px 0px; }
.portfolio.type8:hover figure img { -webkit-transform: scale(1); -moz-transform: scale(1); -ms-transform: scale(1); transform: scale(1); }
.portfolio.type8 .image-overlay h2 { font-size:18px; padding-bottom:30px; }
.portfolio.type8 .image-overlay-details .categories { font-size:14px; position:absolute; bottom:60px; left:0px; right:0px; }
.portfolio.type8 .image-overlay-details { border:0px; text-align:center; width:100%; left:0px; right:0px; top:0px; bottom:0px; margin:auto; height:120px; }
.portfolio.type8 .image-overlay .links:after { background:none; }
.portfolio.type8 .image-overlay-details:before { content:""; position:absolute; width:40px; height:2px; background:#ffffff; left:0px; right:0px; bottom:45px; margin:auto; }

.portfolio.type8 .image-overlay .links { text-align:center; bottom:0px; height:36px; left:0px; right:0px; top:100px; bottom:0px; width:115px; margin:auto; }
.portfolio.type8 .image-overlay .links a { border:0px; margin:0px 5px; border-radius:15px 0px; background:#ffffff; color:#ef577a; }
.portfolio.type8 .image-overlay .links a:hover { background:#553737; color:#ffffff; }

.portfolio.type8 .image-overlay .links a { top:100%; bottom:auto; }
.portfolio.type8:hover .image-overlay .links a { top:0; bottom:auto; }

.dt-sc-portfolio-sorting.type8 a { background:#f7f7f7; border:none; border-radius:15px 0px; padding:10px 20px 10px; }

/* Wedding */
.dt-sc-portfolio-sorting.type9 { margin-bottom:40px; }
.dt-sc-portfolio-sorting.type9 a { border-radius:8px; background:#ffffff; border:none; font-size:24px; color:#6e4d27; font-weight:normal; letter-spacing:1px; margin:0px 5px 20px; }
.portfolio.type9:hover { -webkit-transform:skewX(3deg) scale(1.2); -moz-transform:skewX(3deg) scale(1.2); -ms-transform:skewX(3deg) scale(1.2); transform:skewX(3deg) scale(1.2); z-index:1; }
.portfolio.type9 figure img { border:5px solid #ffffff; border-radius:10px; box-sizing:border-box; }
.portfolio.type9 .image-overlay { border-radius:5px; left:5px; top:5px; width:96.5%; height:97%; width:-webkit-calc(100% - 10px); width:-moz-calc(100% - 10px); width:calc(100% - 10px); height:-webkit-calc(100% - 10px); height:-moz-calc(100% - 10px); height:calc(100% - 10px); }

.portfolio.type9:hover figure img { -webkit-transform:scale(1); -moz-transform:scale(1); -ms-transform:scale(1); transform:scale(1); }
.portfolio.type9 figure { overflow:inherit; }
.portfolio.type9 .image-overlay .links:after { background:none; }
.portfolio.type9 .image-overlay .links { text-align:center; width:100%; height:100%; }
.portfolio.type9 .image-overlay .links a { border:none; font-size:40px; }
.portfolio.type9 .image-overlay .links a:last-child { margin-left:0px; }
.portfolio.type9 .image-overlay .links, .portfolio.type9 .image-overlay .links a { left:0px; right:0px; top:0px; bottom:0px; margin:auto; height:44px; }
.portfolio.type9 .image-overlay .links a:hover { background:none; color:#6e4d27; }

/* Portfolio Columns */
.dt-sc-portfolio-container .portfolio { margin-left:0px; margin-right:0px; }

.page-template-tpl-fullwidth .dt-sc-portfolio-container .portfolio.no-space.dt-sc-one-fourth, .page-template-tpl-portfolio .dt-sc-portfolio-container .portfolio.no-space.dt-sc-one-fourth { width:24.9868%; }

.page-with-sidebar .portfolio.dt-sc-one-half { width:48.9%; }
.with-both-sidebar .portfolio.dt-sc-one-half, .page-with-sidebar.with-both-sidebar .portfolio.dt-sc-one-third, .page-with-sidebar.with-both-sidebar .portfolio.dt-sc-one-fourth { width:48.2%; }
.page-with-sidebar .portfolio.dt-sc-one-third { width:31.6%; }
.page-with-sidebar .portfolio.dt-sc-one-fourth { width:23.2%; }

/* Type 1 */
.page-with-sidebar .portfolio.type1 .image-overlay h2, .portfolio.type1.dt-sc-one-third .image-overlay h2 { font-size:20px; }
.page-with-sidebar .portfolio.type1 .image-overlay .links a, .page-template-tpl-portfolio .portfolio.type1.dt-sc-one-third .image-overlay .links a, .page-template-tpl-portfolio .portfolio.type1.dt-sc-one-fourth .image-overlay .links a { margin:10px 0px 0px 10px; }
.page-with-sidebar .portfolio.type1 .image-overlay .links a:last-child, .page-template-tpl-portfolio .portfolio.type1.dt-sc-one-third .image-overlay .links a:last-child, .page-template-tpl-portfolio .portfolio.type1.dt-sc-one-fourth .image-overlay .links a:last-child { margin-left:5px; }

.with-both-sidebar .portfolio.type1 .image-overlay h2 { font-size:15px; }
.with-both-sidebar .portfolio.type1 .image-overlay .links:after, .page-with-sidebar .portfolio.type1.dt-sc-one-third .image-overlay .links:after, .page-template-tpl-portfolio .portfolio.type1.dt-sc-one-fourth .image-overlay .links:after { background:none; }

.page-with-sidebar .portfolio.type1.dt-sc-one-third .image-overlay h2, .page-template-tpl-portfolio .portfolio.type1.dt-sc-one-fourth .image-overlay h2 { font-size:16px; }

.page-with-sidebar .portfolio.type1.dt-sc-one-fourth .image-overlay-details .categories { display:none; }
.with-both-sidebar .portfolio.type1.dt-sc-one-fourth .image-overlay-details .categories { display:block; }

.page-with-sidebar .portfolio.type1.dt-sc-one-fourth .image-overlay h2 { font-size:14px; }
.page-with-sidebar .portfolio.type1.dt-sc-one-fourth .image-overlay .links a { height:30px; width:30px; line-height: 25px; }
.page-with-sidebar .portfolio.type1.dt-sc-one-fourth .image-overlay .links a span { line-height:30px; font-size:14px; }
.page-with-sidebar .portfolio.type1.dt-sc-one-fourth:hover .image-overlay-details { bottom:20px; }

.with-both-sidebar .portfolio.type1.dt-sc-one-fourth .image-overlay .links a { height:40px; width:40px; }
.with-both-sidebar .portfolio.type1.dt-sc-one-fourth .image-overlay .links a span { line-height:40px; font-size:16px; }

/* Type 2 */
.with-both-sidebar .dt-sc-portfolio-sorting.type2 { width:93%; box-sizing:border-box; }
.with-both-sidebar .dt-sc-portfolio-sorting.type2 a { font-size:10px; padding:15px 9px 14px; }
.with-both-sidebar .portfolio.type2 .image-overlay h2 { font-size:12px; }
.with-both-sidebar .portfolio.type2 .image-overlay .links a, .page-with-sidebar .portfolio.type2.dt-sc-one-third .image-overlay .links a, .page-template-tpl-portfolio .portfolio.type2.dt-sc-one-fourth .image-overlay .links a { width:50px; height:50px; }
.with-both-sidebar .portfolio.type2 .image-overlay .links a span, .page-with-sidebar .portfolio.type2.dt-sc-one-third .image-overlay .links a span, .page-template-tpl-portfolio .portfolio.type2.dt-sc-one-fourth .image-overlay .links a span { line-height:50px; font-size:20px; }
.with-both-sidebar .portfolio.type2 .image-overlay .links, .page-with-sidebar .portfolio.type2.dt-sc-one-third .image-overlay .links, .page-template-tpl-portfolio .portfolio.type2.dt-sc-one-fourth .image-overlay .links { bottom:50px; }

.portfolio.type2.dt-sc-one-third .image-overlay h2 { font-size:16px; }

.page-with-sidebar .portfolio.type2.dt-sc-one-third .image-overlay h2 { font-size:11px; }

.page-template-tpl-portfolio .portfolio.type2.dt-sc-one-fourth .image-overlay h2 { font-size:12px; }

.page-with-sidebar .portfolio.type2.dt-sc-one-fourth .image-overlay .links { bottom:65px; }

.with-both-sidebar .portfolio.type2.dt-sc-one-fourth .image-overlay h2 { font-size:11px; }
.page-with-sidebar.with-both-sidebar .portfolio.type2.dt-sc-one-fourth .image-overlay .links { bottom:49px; }

/* Type 3 */
.with-both-sidebar .portfolio.type3 .image-overlay .links a { font-size:12px; }

.page-with-sidebar .portfolio.type3.dt-sc-one-third .image-overlay .links a, .portfolio.type3.dt-sc-one-fourth .image-overlay .links a { font-size:13px; }

.page-with-sidebar .portfolio.type3.dt-sc-one-fourth .image-overlay .links a { font-size:12px; }

/* Type 5 */
.with-both-sidebar .portfolio.type5 .image-overlay-details .categories, .with-both-sidebar .portfolio.type5 .image-overlay-details .categories { font-size:11px; }
.with-both-sidebar .portfolio.type5 .image-overlay h2, .page-with-sidebar .portfolio.type5.dt-sc-one-third .image-overlay h2, .page-template-tpl-portfolio .portfolio.type5.dt-sc-one-fourth .image-overlay h2 { font-size:14px; }

.page-with-sidebar .portfolio.type5.dt-sc-one-fourth .image-overlay h2 { margin-bottom:0px; }

.page-with-sidebar .portfolio.type5.dt-sc-one-fourth .image-overlay-details .categories { display:none; }
.with-both-sidebar .portfolio.type5.dt-sc-one-fourth .image-overlay-details .categories { display:block; }



/* Type 7 */
.with-both-sidebar .portfolio.type7 .image-overlay h2, .page-with-sidebar .portfolio.type7 .image-overlay h2, .portfolio.type7.dt-sc-one-fourth .image-overlay h2 { font-size:12px; }

.page-with-sidebar .portfolio.type7.dt-sc-one-fourth .image-overlay-details .categories { display:none; }
.portfolio.type7.dt-sc-one-fourth .image-overlay-details .categories { display:none; }
.with-both-sidebar .portfolio.type7.dt-sc-one-fourth .image-overlay-details .categories { display:block; }

.page-with-sidebar .portfolio.type7.dt-sc-one-fourth:hover .image-overlay-details { bottom:60px; }

/* Type 8 */
.with-both-sidebar .portfolio.type8 .image-overlay-details .categories, .page-with-sidebar .portfolio.type8.dt-sc-one-third .image-overlay-details .categories, .page-template-tpl-portfolio .portfolio.type8.dt-sc-one-fourth .image-overlay-details .categories { font-size:12px; bottom:70px; }
.with-both-sidebar .portfolio.type8 .image-overlay h2, /*.page-template-tpl-portfolio .portfolio.type8 .image-overlay h2, */.page-with-sidebar .portfolio.type8.dt-sc-one-third .image-overlay h2, .page-template-tpl-portfolio .portfolio.type8.dt-sc-one-fourth .image-overlay h2 { font-size:14px; }
.with-both-sidebar .portfolio.type8 .image-overlay-details:before, .page-with-sidebar .portfolio.type8.dt-sc-one-third .image-overlay-details:before, .page-template-tpl-portfolio .portfolio.type8.dt-sc-one-fourth .image-overlay-details:before { bottom:50px; }

.page-with-sidebar .portfolio.type8.dt-sc-one-fourth .image-overlay-details .categories { display:none; }
.with-both-sidebar .portfolio.type8.dt-sc-one-fourth .image-overlay-details .categories { display:block; }

.page-with-sidebar .portfolio.type8.dt-sc-one-fourth .image-overlay .links { top:60px; }
.page-with-sidebar .portfolio.type8.dt-sc-one-fourth .image-overlay-details:before { bottom:67px; }

.with-both-sidebar.page-with-sidebar .portfolio.type8.dt-sc-one-fourth .image-overlay-details:before { bottom:62px; }


.portfolio-categories, .portfolio-tags { float:left; clear:both; width:100%; margin:15px 0px 0px; padding:0px; }
.portfolio-categories a, .portfolio-tags a { color:#000000; font-size:13px; }
.portfolio-categories i, .portfolio-tags i { font-size:20px; color:#000000; margin:0px 8px 0px 0px; position:relative; top:2px; }

	
.portfolio .image-overlay .links:after, .domain-search-form label, .domain-tld-list { background:rgba(255, 255, 255, 0.1); }

.portfolio .image-overlay .links a, .blog-entry.with-border, .blog-entry .entry-format a, .skin-highlight .dt-sc-button.rounded-border, .skin-highlight .dt-sc-button.bordered { border-color:#ffffff; }

.portfolio .image-overlay-details { border-right-color:#ffffff; }

.portfolio .icon-linked{
	width: 32px;
	height: 32px;
	display: block;
	background-image: url(~@/assets/img/lupa.png);
	background-size: contain;
	background-repeat: no-repeat;
	margin-left: 7px;
	margin-top: 5px;
}
.portfolio figure {
    margin: 0 0 0rem;
}

.text-sm{
	font-size: 20px;

}
.portfolio.type1 .image-overlay .links a {
    margin: 5px;
}
.portfolio .image-overlay-details h4{
	font-weight: bold;
}
@media(min-width:992px) and (max-width:1600px){
	.portfolio .image-overlay-details h4{
		font-size: 19px !important;
	}
	.portfolio .image-overlay-details p{
		line-height: 0.9rem;
	}
	.portfolio .image-overlay-details h4{
		line-height: 1rem;
	}
}
@media screen and (-webkit-min-device-pixel-ratio:0) {

    .dt-sc-portfolio-container .portfolio { overflow: hidden; }
    .university-student-form input[type="submit"] { padding-top: 13px; }

    /* Safari override */
    ::i-block-chrome, .widget .recent-posts-widget li .entry-title { line-height: normal; }
    ::i-block-chrome, .widget .recent-posts-widget li .thumb { margin-top: 5px; }
    ::i-block-chrome, .widget .recent-posts-widget li .entry-title h4 { display:inline; }

    ::i-block-chrome, .dt-sc-contact-details-on-map .dt-sc-map-overlay { display: block; }
    ::i-block-chrome, .menu-with-slanting-splitter #main-menu ul.menu > li::before { right: 0; }

    ::i-block-chrome, .portfolio.type3:before, .portfolio.type3 .image-overlay { height: auto; width: auto; }
    ::i-block-chrome, .portfolio.type3:before { bottom: 12px; right: 12px; }
    ::i-block-chrome, .portfolio.type3 .image-overlay { bottom: 20px; right: 20px; }

    ::i-block-chrome, .footer-copyright .menu-links li { margin: 0 0 0 28px; }

}
       
</style>