<template >
    <div class="sidebar h-screen w-9/12 bg-orange-400 fixed top-0 flex flex-col justify-center items-center">
        <ul>
            <li class="nav-item lg:border-r-2 lg:border-white lg:hover:border-t-2 lg:hover:border-l-2 lg:hover:border-b-2">
                <a class="px-3 py-2 flex items-center text-lg md:text-sm lg:text-lg uppercase font-medium leading-snug text-white" href="#internos">
                    <span class="ml-2">Empreeendimento</span>
                </a>
            </li>
            <li class="nav-item lg:border-r-2 lg:border-white lg:hover:border-t-2 lg:hover:border-r-2 lg:hover:border-b-2">
                <a class="px-3 py-2 flex items-center text-lg md:text-sm lg:text-lg uppercase font-medium leading-snug text-white" href="#mapa">
                    <span class="ml-2">Localização</span>
                </a>
            </li>
            <li class="nav-item lg:border-r-2 lg:border-white lg:hover:border-t-2 lg:hover:border-r-2 lg:hover:border-b-2">
                <span @click="$emit('openModal', 'Saiba Mais')" class="px-3 py-2 flex items-center text-lg md:text-sm lg:text-lg uppercase font-medium leading-snug text-white cursor-pointer">
                    <span class="ml-2">Saiba Mais</span>
                </span>
            </li>
            <li class="nav-item lg:hover:border-t-2 lg:hover:border-r-2 lg:hover:border-b-2">
                <span @click="$emit('openModal', 'Fale conosco')"  class="px-3 py-2 flex items-center text-lg md:text-sm lg:text-lg uppercase font-medium leading-snug text-white cursor-pointer">
                    <span class="ml-2">Fale conosco</span>
                </span>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: 'SideBarComponent',
    emits: ['openModal'],
}
</script>
<style scoped>
    .sidebar{
        box-shadow:  2px 15px 1px rgba(255,255,255,255.75);
        z-index: 100;
    }
    .sidebar::after{
        content: '';
        width: 10rem;
        height: 5rem;
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: white;
        transform: skewY(-63deg);
    }
</style>