<template>
    <div class="section-1">
        <div class="mx-auto">
            <div class="lg:grid lg:grid-cols-2 lg:items-center">
                <p class="p-14 text-justify text-sm xl:p-0 xl:ml-20 mx-auto xl:text-lg text-gray-700 lg:text-left lg:w-11/12 leading-6">
                    Morar no <strong>Itaigara</strong> resgata o que a vida na cidade tem de melhor. 
                    Um bairro nobre que 
                    promove a integração entre as pessoas e a vizinhança. 
                    Perfeito para caminhar, praticar 
                    esportes e desfrutar dos prazeres do dia a dia. 
                    <strong>Move</strong> é a mobilidade da vida moderna 
                    aliada à sofisticação que o seu bom gosto exige, com a assinatura de uma das mais 
                    conceituadas constrututoras do mercado. Seja para morar ou investir, o que há de melhor 
                    está reunido aqui.
                    <b class="2xl:block">Você vai se surpreender</b>
                </p>
                <div class="xl:flex xl:justify-end lg:pt-6">   
                    <img src="@/assets/img/casal.png" alt="" class="object-cover lg:h-96 lg:w-auto img">
                </div>
            </div>
        </div>
        <div class="lg:border-b-2 w-9/12 lg:w-auto lg:border-orange-400 xl:w-9/12 2xl:w-9/12">
            <div @click="$emit('openModal', 'Baixar Book')" class="bg-orange-400 relative button text-white p-2 lg:p-4 lg:w-2/6  xl:w-2/5 2xl:w-2/6 text-center skew cursor-pointer">
                <div class="text-xl uppercase skew-text">Baixar Book</div>
            </div>
        </div>
        
    </div>
   
</template>
<script>
export default {
    name: "SectionOne",
    emits: ['openModal'],
}
</script>
<style scoped>    
    .skew{
        transform: skew(-30deg);
        -webkit-transform: skew(-30deg);
        -moz-transform: skew(-30deg);
        -o-transform: skew(-30deg);
        margin-left: -5%;
    }
    .skew-text{
        transform: skew(30deg);
        -webkit-transform: skew(30deg);
        -moz-transform: skew(30deg);
        -o-transform: skew(30deg);
        margin-left: -5%;
    }
    .img{
        margin-bottom: -68.9px;
    }
    .button{
        transition: all 0.5s;
        border-top: 2px solid rgb(255 255 255);
    }
    .button:after{
        content: '';
        width: 32px;
        top: -0px;
        right: -1px;
        height: 85%;
        display: block;
        background: rgb(251 146 60);
        position: absolute;
        transition: all 1s;
        box-shadow: 10px 10px rgb(251 146 60);
        border-right: 2px solid rgb(255, 255, 255);
        border-bottom: 2px solid rgb(255, 255, 255);
    }
    .button:hover{
        border-top: 2px solid rgb(251 146 60);
        background: rgb(255, 255, 255);
        
        color: rgb(251 146 60);
    }
    .button:hover::after{
        border-right: 2px solid rgb(255, 255, 255);
        border-bottom: 2px solid rgb(255, 255, 255);
    }
    @media(max-width:1023px){
        .button{
            border-bottom: 2px solid rgb(255, 255, 255);
        }
        .button:hover{
            border-bottom: 2px solid rgb(251 146 60);
        }
    }
</style>