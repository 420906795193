<template>
    <div class="w-full fixed menu-pc"><!-- fixed -->
        <nav class="relative flex flex-wrap items-center justify-between px-2 lg:py-3 2xl:py-5 bg-orange-400 mb-3">
            <div class="container px-4 mx-auto flex items-center justify-between">
                <div class="w-full relative flex justify-between lg:w-auto  px-4 lg:static lg:block lg:justify-start">
                    <a class="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase text-white" href="/">
                        <img src="@/assets/img/logo-move-itaigara.png" alt="Move Itaigara" class="h-11 object-fill">
                    </a>
                    <span class="text-white cursor-pointer text-2xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none" v-on:click="toggleNavbar()">
                        <img :src="!showMenu ? require('@/assets/img/menu.png') : require('@/assets/img/close-menu.png')" alt="close Menu" />
                    </span>
                </div>
                <div class="hidden lg:flex lg:flex-grow items-center">
                    <ul class="flex flex-col lg:flex-row list-none ml-auto">
                    <li class="nav-item lg:border-r-2 lg:border-white">
                        <a class="px-3 pr-5 py-2 flex items-center text-xs md:text-sm 2xl:text-lg uppercase font-medium leading-snug text-white" href="#internos">
                            <span class="ml-2">Empreeendimento</span>
                        </a>
                    </li>
                    <li class="nav-item lg:border-r-2 lg:border-white ">
                        <a class="px-3 pr-5 py-2 flex items-center text-xs md:text-sm 2xl:text-lg uppercase font-medium leading-snug text-white" href="#mapa">
                            <span class="ml-2">Localização</span>
                        </a>
                    </li>
                    <li class="nav-item lg:border-r-2 lg:border-white ">
                        <span @click="$emit('openModal', 'Saiba Mais')" class="px-3 pr-5 py-2 flex items-center text-xs md:text-sm 2xl:text-lg uppercase font-medium leading-snug text-white cursor-pointer">
                            <span class="ml-2">Saiba Mais</span>
                        </span>
                    </li>
                    <li class="nav-item ">
                        <span  @click="$emit('openModal', 'Fale conosco')" class="px-3 pr-5 py-2 flex items-center text-xs md:text-sm 2xl:text-lg uppercase font-medium leading-snug text-white cursor-pointer">
                            <span class="ml-2">Fale conosco</span>
                        </span>
                    </li>
                    </ul>
                </div>
            </div>
        </nav>
        <Transition name="side">
            <SideBarComponent v-if="showMenu" @open-modal="$emit('openModal', $event)"  />
        </Transition>
    </div>
</template>
<script>

import SideBarComponent from "./SideBarComponent.vue";
export default {
    name: "NavbarComponent",
    emits: ['openModal'],
    components: { SideBarComponent },
    data() {
        return {
            showMenu: false
        };
    },
    methods: {
        toggleNavbar() {
            this.showMenu = !this.showMenu;
        }
    },
    
    mounted() {
        var lastScrollTop = 10;
        window.addEventListener("scroll", function(){
            if(screen.width > 1023){
                var st = window.scrollY;
                const menu = document.querySelector('.menu-pc');
                if (st > lastScrollTop || st < 10){
                   menu.style.opacity = "1";
                    
                }else{
                   menu.style.opacity = "0";
                }
                lastScrollTop = st;
            }
        })
    
    },
}
</script>
<style scoped>
    .menu-pc{
        transition:all 0.5s ease-out;
        opacity: 1;
    }
    .side-enter-active,
    .side-leave-active {
        transition: opacity 0.5s ease;
    }

    .side-enter-from,
    .side-leave-to {
        opacity: 0;
    }
    /* nav{
        border-bottom: 8px solid white;
    } */
    .nav-item a,.cursor-pointer{
        transition: 0.1s;
    }
    .nav-item a:hover,.cursor-pointer:hover {
        transform: scale(1.05);
    }
</style>