<template>
    <header class="relative h-screen  pb-12 overflow-hidden">
        <!-- <video id="video-banner" preload playsinline autoplay loop muted>
            <source src="@/assets/video/move-itaigara-apartamentos-3-quartos-2-quartos.mp4" type="video/mp4" alt="Move Itaigara - Apartamentos 3 quartos e 2 quartos">
        </video> -->
        <img  id="video-banner" src="@/assets/img/move-itaigara-salvador-fachada-apartamentos.jpg" class="w-full" alt="Move Itaigara - Fachada">
        <div class="absolute w-full h-screen flex items-center bg-[rgba(0,0,0,0.15)]">
            <div class="p-14 xl:p-0 xl:ml-20 mx-auto text-white flex flex-col xl:space-y-1">
                <h1 class="text-4xl lg:text-5xl 2xl:text-6xl xl:mb-2 uppercase">MOVE ITAIGARA</h1>
                <h2 class="text-2xl lg:text-5xl 2xl:text-6xl font-semibold uppercase">VOCÊ VIZINHO DE TUDO</h2>
                <h3 class="text-xl lg:text-3xl">Apartamentos de 3 quartos (suíte) com lavabo, 2 quartos (suíte)</h3>
            </div>
            <span class="absolute bottom-5 left-5 text-xl md:text-2xl xl:text-2xl 2xl:text-4xl text-white apartir font-bold overflow-hidden pt-2"><!--<div class="ml-10 xl:ml-20 xl:pr-10 mb-3 text">A partir de R$ 698 mil</div>--> <div class="text-center text-lg md:text-xl lg:text-2xl 2xl:text-3xl bg-white text-gray-700 w-[110%] mx-auto px-4 py-2"><span class="ml-[-10%]">Obras iniciadas</span></div></span>
        </div>
        <NavBarComponent @open-modal="$emit('openModal', $event)"  class="z-50" />
        <div class="absolute triangle-bottom-right bottom-0 right-0 z-20"></div>
        <div class="ilustration absolute z-20"></div>
        <a :href="`https://wa.me/5571999691478?text=Olá! ${$route.query.utm_source && $route.query.utm_source != 'direct' ? 'Vi seu anúncio no ' + $route.query.utm_source + '.' :''}%20Gostaria%20de%20saber%20mais%20sobre%20o%20Move%20Itaigara`" target="_blank"  class="whats-btn fixed bottom-8 right-8"></a>
    </header>
</template>
<script>
import NavBarComponent from "./NavBarComponent.vue";
export default {
    name: "BannerComponent",
    emits: ['openModal'],
    components: { NavBarComponent }
}
</script>
<style scoped>
    .whats-btn {
        display: inline-block;
        width:60px;
        height: 60px;
        background-image: url(~@/assets/img/whatsapp.png);
        background-size: contain;
        background-repeat: no-repeat;
        z-index: 9999;
        cursor: pointer;
        transition: all .5s;
    }
    .whats-btn:hover{
        transform: scale(1.1);
    }
    #video-banner {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: 0;
        object-fit: cover;
        -ms-transform: translateX(-50%) translateY(-50%);
        -moz-transform: translateX(-50%) translateY(-50%);
        -webkit-transform: translateX(-50%) translateY(-50%);
        -o-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
    }
    .ilustration{
        bottom: 0;
        right: 0rem;
        width: 200px;
        height: 20px;
        background-color: white;
        transform: skewY(-63deg);
        animation: ilustration 2s;
    }
    .apartir{
        /* padding: 10px 20px; */
        min-width: 270px;
        left: -30px;
        background-color: rgb(251 146 60);
        transform: skew(30deg);
    }
    @media(max-width:768px){
        .apartir .text{
            margin-left: 20%;
        }
        .ilustration{
            display: none !important;
        }
        .triangle-bottom-right{
            display: none !important;
        }
    }
    @media(min-width:768px) and (max-width:1280px){
        .apartir{
          min-width: 300px;
        }
        .apartir .text{
            margin-left: 20%;
        }
    }
    @media(min-width:992px){
        .apartir{
          min-width: 350px;
        }
    }
    .apartir div{
       transform: skew(-30deg); 
    }
     @keyframes ilustration {
        from {
            width: 0;
        }
        to {
            width: 200px;
            
        }
        
    } 
    .triangle-bottom-right {
      width: 0;
      height: 0;
      border-bottom: 200px solid rgb(251 146 60);
      border-left: 100px solid transparent;
      animation: triangulo 2s;
    }
    @keyframes triangulo {
        from {
            border-bottom: 0px solid rgb(251 146 60);
            border-left: 0px solid transparent;
        }
        to {
            border-bottom: 200px solid rgb(251 146 60);
            border-left: 100px solid transparent;
            
        }
        
    } 
    @keyframes zoom-in {
        0% {
            transform: scale(1, 1);
        }
        50% {
            transform: scale(1.15, 1.15);
        }
        100% {
            transform: scale(1, 1);
        }
    }
    @media (min-width: 1024px) { 
        .ilustration{
            bottom: 0;
            right: 0rem;
            width: 300px;
            height: 20px;
            background-color: white;
            transform: skewY(-63deg);
            animation: ilustration 2s;
        }
        @keyframes ilustration {
            from {
                width: 0;
            }
            to {
                width: 300px;
                
            }
            
        } 
        .triangle-bottom-right{
            border-bottom: 300px solid rgb(251 146 60);
            border-left: 150px solid transparent;
            animation: triangulo 2s;
        }
        @keyframes triangulo {
            from {
                border-bottom: 0px solid rgb(251 146 60);
                border-left: 0px solid transparent;
            }
            to {
                border-bottom: 300px solid rgb(251 146 60);
                border-left: 150px solid transparent;
                
            }
            
        }   
         
    }
    @media (min-width: 1536px) { 

        .ilustration{
            bottom: 0;
            right: 0rem;
            width: 600px;
            height: 20px;
            background-color: white;
            transform: skewY(-63deg);
            animation: ilustration 2s;
        }
        @keyframes ilustration {
            from {
                width: 0;
            }
            to {
                width: 600px;
                
            }
            
        } 
        .triangle-bottom-right{
            border-bottom: 600px solid rgb(251 146 60);
            border-left: 300px solid transparent;
            animation: triangulo 2s;
        }  
        @keyframes triangulo {
            from {
                border-bottom: 0px solid rgb(251 146 60);
                border-left: 0px solid transparent;
            }
            to {
                border-bottom: 600px solid rgb(251 146 60);
                border-left: 300px solid transparent;
                
            }
            
        }
     }
</style>