<template>
    <div class="section-4 relative"  id="internos">
        <div class="mx-auto relative overflow-hidden">
            <div class="lg:grid lg:grid-cols-2  lg:items-center">
                <div class="z-0 bg-orange-400 text-white uppercase div-skew w-11/12">
                   
                    <div class="p-7 ml-14 2xl:p-10 xl:ml-32 text-xl 2xl:text-2xl lg:text-left">
                         <p class="flex flex-col div">
                            <span> <span class="font-semibold">Apartamentos de 2 quartos</span> (SUÍTE)</span>
                            <span><span class="font-semibold">Apartamentos de 3 quartos</span> (SUÍTE) <span class="font-semibold">com lavabo</span></span>
                        </p>
                    </div>
                </div>
                <div class="p-14 text-justify text-sm lg:pl-7 lg:p-7 xl:text-lg text-gray-700 lg:text-left leading-6">
                    <p class="lg:flex lg:flex-col"> <span> Uma experiência única em morar bem. Ambientes que </span>
                        <span>promovem a integração</span>
                    </p>
                   
                </div>
            </div>
            <!-- <div class="hidden xl:block text-white text-xl md:text-2xl lg:text-xl 2xl:text-3xl absolute right-10 top-10 font-bold apartir2 bg-orange-400 overflow-hidden">
                <p>A partir de R$ 698 mil</p>
            </div> -->
        </div>
        
    </div>
   
</template>
<script>
export default {
    name: "SectionFour"
}
</script>
<style scoped>    
    .div-skew{
        transform: skew(-30deg);
        -webkit-transform: skew(-30deg);
        -moz-transform: skew(-30deg);
        -o-transform: skew(-30deg);
        margin-left: -8%;
    }
    .div-skew .div{
        transform: skew(30deg);
        -webkit-transform: skew(30deg);
        -moz-transform: skew(30deg);
        -o-transform: skew(30deg);
    }
    .apartir2{
        transform: skew(30deg);
        -webkit-transform: skew(30deg);
        -moz-transform: skew(30deg);
        -o-transform: skew(30deg);
        padding: 10px 60px;
        right: -15px;
    }
    .apartir2 p {
        transform: skew(-30deg);
        -webkit-transform: skew(-30deg);
        -moz-transform: skew(-30deg);
        -o-transform: skew(-30deg);
    }
    @media(max-width:1535px){
        .apartir2{
            transform: skew(30deg);
            -webkit-transform: skew(30deg);
            -moz-transform: skew(30deg);
            -o-transform: skew(30deg);
            padding: 5px 25px;
            right: -15px;
        }
    }
</style>