<template>
    <swiper :pagination="{clickable: true}" :navigation="true" :modules="modules" class="mySwiper">
        <swiper-slide v-for="(img, index) in images" :key="index" class="relative uppercase">
            <img :src="img.src" :alt="`Move itaigara - ${img.title}`" class="object-contain h-full w-full" :title="`Move Itaigara - ${img.title}`">
            <a :href="img.alta" :data-glightbox="`title:${img.title};`" class="glightboxPerspectivas absolute top-2 right-2 w-8 h-8 lg:top-10 lg:right-10 ampliar inline-block lg:w-14 lg:h-14" title="Ampliar"></a>
            <p class="absolute bottom-1 left-1 lg:left-10 lg:bottom-10 text-white font-bold text-xs md:text-sm lg:text-lg">{{ img.title }}</p>
        </swiper-slide>
    </swiper>  
</template>
<script>

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { Navigation, Pagination } from "swiper";

export default {
    name: 'CarouselComponent',

    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        const images = [
                {src: require("@/assets/img/perspectivas/Move-Itaigara-Sala-com-sugestao-home-office.jpg"), title: "SALA COM OPÇÃO DE HOME OFFICE", alta: require("@/assets/img/perspectivas/alta/Move-Itaigara-Sala-com-sugestao-home-office-full.jpg")},
                {src: require("@/assets/img/perspectivas/Move-Itaigara-Varanda-3-quartos.jpg"), title: "VARANDA - Apartamentos de 3 QUARTOS", alta: require("@/assets/img/perspectivas/alta/Move-Itaigara-Varanda-3-quartos-full.jpg")},
                {src: require("@/assets/img/perspectivas/Move-Itaigara-Suite-Master-3-quartos.jpg"), title: "SUÍTE MASTER - Apartamentos de 3 QUARTOS", alta: require("@/assets/img/perspectivas/alta/Move-Itaigara-Suite-Master-3-quartos-full.jpg")},
                
                {src: require("@/assets/img/perspectivas/Move-Itaigara-Sala-2-quartos.jpg"), title: "SALA - Apartamentos de 2 QUARTOS", alta: require("@/assets/img/perspectivas/alta/Move-Itaigara-Sala-2-quartos-full.jpg")},
                {src: require("@/assets/img/perspectivas/Move-Itaigara-Sugestao-com-cozinha-integrada-2-quartos.jpg"), title: "OPÇÃO COM COZINHA INTEGRADA – Apartamentos de 2 QUARTOS", alta: require("@/assets/img/perspectivas/alta/Move-Itaigara-Sugestao-com-cozinha-integrada-2-quartos-full.jpg")},
                {src: require("@/assets/img/perspectivas/Move-Itaigara-Suite-master-2-quartos.jpg"), title: "SUÍTE MASTER - Apartamentos de 2 QUARTOS", alta: require("@/assets/img/perspectivas/alta/Move-Itaigara-Suite-master-2-quartos-full.jpg")},
            ];

        return {
            images,
            modules: [Navigation, Pagination],
        };
    },
    mounted() {
        GLightbox({selector: '.glightboxPerspectivas'});
    },
};

</script>
<style >
    .mySwiper .swiper-button-prev {
       color:white  !important;
       border: 2px solid #ffff;
       border-radius: 50%;
       padding: 20px;
    }
    .mySwiper .swiper-button-prev::after ,.swiper-button-next::after {
        font-size: 1.2rem !important;
    }
    .mySwiper .swiper-button-next {
       color:white !important;
       border: 2px solid #ffff;
       border-radius: 50%;
       padding: 20px;
    }
    
    :root {
        --swiper-theme-color: #ffff;
    }
    .mySwiper .swiper-pagination-bullet {
        width: var(--swiper-pagination-bullet-width,var(--swiper-pagination-bullet-size,12px));
        height: var(--swiper-pagination-bullet-height,var(--swiper-pagination-bullet-size,12px));
        background: var(--swiper-pagination-bullet-inactive-color,#ffff);
        opacity: var(--swiper-pagination-bullet-inactive-opacity, .7);
    }
    .mySwiper .swiper-pagination-bullet-active{
        background: #fb923c !important;
        opacity: 1;
    }
    .ampliar{  
        background-image: url(~@/assets/img/ampliar.png);
        background-size: contain;
        background-repeat: no-repeat;
        transition: all 0.5s;
    }
    .ampliar:hover{
        transform: scale(1.1);
        cursor: pointer;
    }   
    .glightbox-mobile .goverlay {
        background: rgba(251, 146, 60,0.9);
    }
    .glightbox-mobile .glightbox-container .gslide-description {
        background: none !important;
    }
    .glightbox-mobile .gslide-desc {
        color: #ffff !important;
    }
</style>

