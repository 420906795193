<template>
    <div class="section-3 relative">
        <div class="mx-auto">
            <div class="lg:grid lg:grid-cols-2">
                <div class="z-0">
                    <div class="p-14 lg:mt-14 text-justify text-sm xl:p-0 xl:ml-24 mx-auto xl:text-lg text-gray-700 lg:text-left lg:w-11/12 leading-6">
                        <div class="triangulo absolute left-0 top-0"></div>
                        <h2 class="text-orange-400 text-xl xl:text-4xl uppercase"> <span>Curta </span><span class="font-bold"> experiências,</span></h2>
                        <h2 class="text-orange-400 text-xl xl:text-4xl uppercase"> <span>Compartilhe </span><span class="font-bold"> Momentos,</span></h2>

                        <p class="mt-6 mb-7">
                            Desfrute da gastronomia e divirta-se com 
                            a família na Praça Ana Lúcia 
                            Magalhães. Mantenha a forma, respire ar puro e aprecie a natureza no Parque 
                            da Cidade. 
                            Aproveite as facilidades do <strong> Shopping Itaigara</strong>, <strong> TriCenter </strong>, 
                             <strong>Boulevard 161 </strong> e do <strong>Shopping Paseo</strong>
                        </p>
                        <div class="mb-7 lg:w-9/12">
                            <img src="@/assets/img/mobilidade_experiencias_bemestar.png" alt="Move Itaigara - Mobilidade Experiências e Bem-Estar" class="w-full">
                        </div>
                    </div>
                    <div class="mb-4"> 
                        <img src="@/assets/img/Move-Itaigara-Shopping-Paseo.png" alt="Move Itaigara - Shopping Paseo" class="object-cover">
                    </div>
                </div>
                <div class="xl:flex xl:justify-end lg:pt-6 z-10 ">   
                    <img src="@/assets/img/Move-Itaigara-Praca-Ana-Lucia-Magalhaes.png" alt="Move Itaigara - Praça Ana Lúcia Magalhães" class="img-fluid">
                </div>
            </div>
        </div>
        
    </div>
   
</template>
<script>
export default {
    name: "SectionThree"
}
</script>
<style scoped>    
    .triangulo{
        width: 0;
        height: 0;
        border-top: 300px solid #62614b;
        border-right: 100px solid transparent;
    }
    .img-fluid {
        display: block;
        max-width: 100%;
        height: auto;
    }
    @media(max-width:1023px){
        .triangulo{
            width: 0;
            height: 0;
            border-top: 160px solid #62614b;
            border-right: 50px solid transparent;
        }    
    }
</style>