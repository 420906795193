<template>
    <div class="carousel-plantas relative">
        <div class="mx-auto">
            <div>
                    
                <swiper @slideChange="onSlideChange($event)" :pagination="{clickable: true}" :navigation="true" :modules="modules" class="mySwiper2 bg-[#dddad3] lg:max-h-screen">
                   
                    <swiper-slide class="z-10 overflow-hidden lg:max-h-screen" >
                        
                        <div class="lg:grid lg:grid-cols-3 lg:items-center">
                            <div class="bg-white h-full z-20">
                                <div class="z-20 bg-orange-400 text-white uppercase div-skew w-full">
                                    <div class="p-7 py-3 xl:py-14 ml-14 2xl:p-16 2xl:pl-28 sm:pl-28 xl:ml-14 text-lg 2xl:text-2xl lg:text-left">
                                        <h2 class="flex flex-col div desc-img-active">
                                            <span>OPÇÃO DE PLANTA:</span> 
                                            <span><span class="font-semibold">Apartamentos de 3 QUARTOS</span> COM <span class="font-semibold">1 SUÍTE</span> </span>
                                            <span><span class="font-semibold">LAVABO</span> E <span class="font-semibold">VARANDA GOURMET</span> </span>
                                        </h2>
                                    </div>
                                </div>
                                <div class="lg:h-4/6 flex items-center relative">
                                    <img src="@/assets/img/plantas/Move-Itaigara-201_Pbh_Calunga_R03.jpg" class="object-cover w-full mb-8 pt-4 lg:pt-0">
                                  
                                </div>
                            </div> 
                            <div class="lg:10 xl:p-14 2xl:p-24 col-span-2 flex justify-center items-center">
                                <div class="relative w-2/3">
                                    <picture>
                                        <source media="(max-width: 640px)" srcset="@/assets/img/plantas/move-itaigara-planta-3-quartos-com-1-suite-lavabo-e-varanda-goumet-640px.jpg">
                                        <img src="@/assets/img/plantas/move-itaigara-planta-3-quartos-com-1-suite-lavabo-e-varanda-goumet.jpg" class="object-cover w-full" alt="OPÇÃO DE PLANTA: APARTAMENTOS DE 3 QUARTOS COM 1 SUÍTE, LAVABO E VARANDA GOURMET">
                                    </picture>
                                   
                                    <a :href="require('@/assets/img/plantas/alta/move-itaigara-planta-3-quartos-com-1-suite-lavabo-e-varanda-goumet.jpg')" data-glightbox="`title:OPÇÃO DE PLANTA: APARTAMENTOS DE 3 QUARTOS COM 1 SUÍTE, LAVABO E VARANDA GOURMET;" class="glightboxPlantas absolute top-10 left-2 w-8 h-8 lg:top-3 lg:left-auto lg:right-3 ampliar inline-block lg:w-14 lg:h-14" title="Ampliar"></a>
                                </div>
                               
                            </div> 
                        </div>
                    </swiper-slide>

                    <swiper-slide class="z-10 overflow-hidden" >
                        
                        <div class="lg:grid lg:grid-cols-3 lg:items-center">
                            <div class="bg-white h-full z-20">
                                <div class="z-20 bg-orange-400 text-white uppercase div-skew w-full">
                                    <div class="p-7 py-3 xl:py-14 ml-14 2xl:p-16 2xl:pl-28 sm:pl-28 xl:ml-14 text-lg 2xl:text-2xl lg:text-left">
                                        <h2 class="flex flex-col div desc-img-active">  
                                            <span>OPÇÃO DE PLANTA:</span> 
                                            <span><span class="font-semibold">Apartamentos de 3 QUARTOS</span></span>
                                            <span>COM 2 <span class="font-semibold">SUÍTES</span>, <span class="font-semibold">LAVABO</span> E <span class="font-semibold">VARANDA GOURMET</span></span>
                                        </h2>
                                    </div>
                                </div>
                                <div class="lg:h-4/6 flex items-center relative">
                                    <img src="@/assets/img/plantas/Move-Itaigara-201_Pbh_Calunga_R03.jpg" class="object-cover w-full mb-8 pt-4 lg:pt-0">
                                  
                                </div>
                            </div> 
                            <div class="lg:10 xl:p-14 2xl:p-24 col-span-2 flex justify-center items-center">
                                <div class="relative w-2/3">

                                    <picture>
                                        <source media="(max-width: 640px)" srcset="@/assets/img/plantas/move-itaigara-opcao-planta-3-quartos-com-2-suites-e-lavabo.jpg">
                                        <img src="@/assets/img/plantas/move-itaigara-opcao-planta-3-quartos-com-2-suites-e-lavabo.jpg" class="object-cover w-full" alt="OPÇÃO DE PLANTA: APARTAMENTOS DE 3 QUARTOS COM 2 SUÍTES, LAVABO E VARANDA GOURMET">
                                    </picture>
                                    
                                    <a :href="require('@/assets/img/plantas/alta/move-itaigara-opcao-planta-3-quartos-com-2-suites-e-lavabo.jpg')" data-glightbox="title:OPÇÃO DE PLANTA: APARTAMENTOS DE 3 QUARTOS COM 2 SUÍTES, LAVABO E VARANDA GOURMET;" class="glightboxPlantas absolute top-10 left-2 w-8 h-8 lg:top-3 lg:left-auto lg:right-3 ampliar inline-block lg:w-14 lg:h-14" title="Ampliar"></a>
                                </div>
                            </div> 
                        </div>
                    </swiper-slide>

                    <swiper-slide class="z-10 overflow-hidden" >
                        
                        <div class="lg:grid lg:grid-cols-3 lg:items-center">
                            <div class="bg-white h-full z-20">
                                <div class="z-20 bg-orange-400 text-white uppercase div-skew w-full">
                                    <div class="p-7 py-3 xl:py-14 ml-14 2xl:p-16 2xl:pl-28 sm:pl-28 xl:ml-14 text-lg 2xl:text-2xl lg:text-left">
                                        <h2 class="flex flex-col div desc-img-active">  
                                            <span>OPÇÃO DE PLANTA:</span> 
                                           <span><span class="font-semibold">Apartamentos com 2 Suítes</span>, <span class="font-semibold">LAVABO, </span></span>
                                           <span><span class="font-semibold">HOME OFFICE</span> E <span class="font-semibold">VARANDA GOURMET</span></span>
                                           
                                       </h2>
                                    </div>
                                </div>
                                <div class="lg:h-4/6 flex items-center relative">
                                    <img src="@/assets/img/plantas/Move-Itaigara-201_Pbh_Calunga_R03.jpg" class="object-cover w-full mb-8 pt-4 lg:pt-0">
                                  
                                </div>
                            </div> 
                            <div class="lg:10 xl:p-14 2xl:p-24 col-span-2 flex justify-center items-center">
                                <div class="relative w-2/3">

                                    <picture>
                                        <source media="(max-width: 640px)" srcset="@/assets/img/plantas/move-itaigara-opcao-planta-2-suites-home-office-e-varanda-goumet-640px.jpg">
                                        <img src="@/assets/img/plantas/move-itaigara-opcao-planta-2-suites-home-office-e-varanda-goumet.jpg" class="object-cover w-full" alt="OPÇÃO DE PLANTA: Apartamentos com 2 Suítes LAVABO, HOME OFFICE E VARANDA GOURMET;">
                                    </picture>
                                    
                                    <a :href="require('@/assets/img/plantas/alta/move-itaigara-opcao-planta-2-suites-home-office-e-varanda-goumet.jpg')" data-glightbox="`title:OPÇÃO DE PLANTA: Apartamentos com 2 Suítes LAVABO, HOME OFFICE E VARANDA GOURMET;" class="glightboxPlantas absolute top-10 left-2 w-8 h-8 lg:top-3 lg:left-auto lg:right-3 ampliar inline-block lg:w-14 lg:h-14" title="Ampliar"></a>
                                </div>
                            </div> 
                        </div>
                    </swiper-slide>

                    <swiper-slide class="z-10 overflow-hidden" >
                        
                        <div class="lg:grid lg:grid-cols-3 lg:items-center">
                            <div class="bg-white h-full z-20">
                                <div class="z-20 bg-orange-400 text-white uppercase div-skew w-full">
                                    <div class="p-7 py-3 xl:py-14 ml-14 2xl:p-16 2xl:pl-28 sm:pl-28 xl:ml-14 text-lg 2xl:text-2xl lg:text-left">
                                        <h2 class="flex flex-col div desc-img-active">
                                            <span>OPÇÃO DE PLANTA:</span> 
                                            <span><span class="font-semibold">Apartamentos de 2 QUARTOS</span> COM </span>
                                            <span><span class="font-semibold">1 SUÍTE </span> E <span class="font-semibold">VARANDA GOURMET </span></span>
                                        </h2>
                                    </div>
                                </div>
                                <div class="lg:h-4/6 flex items-center relative">
                                    <img src="@/assets/img/plantas/Move-Itaigara-calunga_2.jpg" class="object-cover w-full mb-8 pt-4 lg:pt-0" alt="Calunga">
                                  
                                </div>
                            </div> 
                            <div class="lg:10 xl:p-14 2xl:p-24 col-span-2 flex justify-center items-center">
                                <div class="relative w-2/3">
                                    <picture>
                                        <source media="(max-width: 640px)" srcset="@/assets/img/plantas/move-itaigara-planta-2-quartos-com-1-suite-e-varanda-goumet-640px.jpg">
                                        <img src="@/assets/img/plantas/move-itaigara-planta-2-quartos-com-1-suite-e-varanda-goumet.jpg" class="object-cover w-full" alt="OPÇÃO DE PLANTA: Apartamentos de 2 QUARTOS COM 1 SUÍTE E VARANDA GOURMET;">
                                    </picture>
                                    <a :href="require('@/assets/img/plantas/alta/move-itaigara-planta-2-quartos-com-1-suite-e-varanda-goumet.jpg')" data-glightbox="`title:OPÇÃO DE PLANTA: Apartamentos de 2 QUARTOS COM 1 SUÍTE E VARANDA GOURMET;" class="glightboxPlantas absolute top-10 left-2 w-8 h-8 lg:top-3 lg:left-auto lg:right-3 ampliar inline-block lg:w-14 lg:h-14" title="Ampliar"></a>
                                </div>
                            </div> 
                        </div>
                    </swiper-slide>
                    
                    <swiper-slide class="z-10 overflow-hidden" >
                        
                        <div class="lg:grid lg:grid-cols-3 lg:items-center">
                            <div class="bg-white h-full z-20">
                                <div class="z-20 bg-orange-400 text-white uppercase div-skew w-full">
                                    <div class="p-7 py-3 xl:py-14 ml-14 2xl:p-16 2xl:pl-28 sm:pl-28 xl:ml-14 text-lg 2xl:text-2xl lg:text-left">
                                        <h2 class="flex flex-col div desc-img-active">
                                            <span>OPÇÃO DE PLANTA:</span>
                                            <span><span class="font-semibold">Apartamentos de 2 QUARTOS</span> COM <span class="font-semibold">1 SUÍTE</span>, <span class="font-semibold">COZINHA INTEGRADA</span> E <span class="font-semibold">VARANDA GOURMET</span></span>
                                        </h2>
                                    </div>
                                </div>
                                <div class="lg:h-4/6 flex items-center relative">
                                    <img src="@/assets/img/plantas/Move-Itaigara-calunga_2.jpg" class="object-cover w-full mb-8 pt-4 lg:pt-0" alt="Calunga">
                                  
                                </div>
                            </div> 
                            <div class="lg:10 xl:p-14 2xl:p-24 col-span-2 flex justify-center items-center">
                                <div class="relative w-2/3">
                                    <picture>
                                        <source media="(max-width: 640px)" srcset="@/assets/img/plantas/move-itaigara-planta-2-quartos-com-1-suite-cozinha-integrada-e-varanda-goumet-640px.jpg">
                                        <img src="@/assets/img/plantas/move-itaigara-planta-2-quartos-com-1-suite-cozinha-integrada-e-varanda-goumet.jpg" class="object-cover w-full" alt="OPÇÃO DE PLANTA: APARTAMENTOS DE 2 QUARTOS COM 1 SUÍTE, COZINHA INTEGRADA E VARANDA GOURMET">
                                    </picture>
                                
                                    <a :href="require('@/assets/img/plantas/alta/move-itaigara-planta-2-quartos-com-1-suite-cozinha-integrada-e-varanda-goumet.jpg')" data-glightbox="`title: OPÇÃO DE PLANTA: APARTAMENTOS DE 2 QUARTOS COM 1 SUÍTE, COZINHA INTEGRADA E VARANDA GOURMET;" class="glightboxPlantas absolute top-10 left-2 w-8 h-8 lg:top-3 lg:left-auto lg:right-3 ampliar inline-block lg:w-14 lg:h-14" title="Ampliar"></a>
                                </div>
                            </div> 
                        </div>
                    </swiper-slide>
                    
                </swiper>  
            </div>
        </div>
        
    </div>
   
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/navigation";
import "swiper/css/pagination";


// import required modules
import { Navigation, Pagination } from "swiper";

export default {
    name: 'CarouselPlantasComponent',

    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        
        const onSlideChange = (e) => {
            
        };   
        return {
            modules: [Navigation, Pagination],
            onSlideChange
        };
    },
    mounted() {
        GLightbox({selector: '.glightboxPlantas'});
    }
};
</script>
<style>    
    .carousel-plantas .div-skew{
        transform: skew(-30deg);
        -webkit-transform: skew(-30deg);
        -moz-transform: skew(-30deg);
        -o-transform: skew(-30deg);
        margin-left: -14%;
        width: calc(100% + 23%);
    }
    .carousel-plantas .div-skew .div{
        transform: skew(30deg);
        -webkit-transform: skew(30deg);
        -moz-transform: skew(30deg);
        -o-transform: skew(30deg);
    }
    .carousel-plantas .mySwiper2 .swiper-button-prev {
       color:#2c2b2b  !important;
       border: 2px solid #2c2b2b;
       border-radius: 50%;
       padding: 20px;
    }
    .carousel-plantas .mySwiper2 .swiper-button-prev::after ,.swiper-button-next::after {
        font-size: 1.2rem !important;
    }
    .carousel-plantas .mySwiper2 .swiper-button-next {
       color:#2c2b2b !important;
       border: 2px solid #2c2b2b;
       border-radius: 50%;
       padding: 20px;
    }
    .carousel-plantas .mySwiper2 .swiper-pagination-bullet-active{
        background-color: #2c2b2b !important;
    }
</style>

