<template>
  <div class="home">
    <Transition>
    <div v-if="!isLoaded" class="fixed z-50 top-0 overflow-hidden w-screen h-screen flex flex- justify-center items-center bg-white" style="z-index:99999">

      <div class="md:w-2/4 xl:w-1/5">
        <img src="@/assets/img/preload-logo.png" class="w-full">
        <img src="@/assets/img/spinner.gif" alt="Loading" class="mx-auto w-24">
      </div>

    </div>
    </Transition>
    
    <Transition>
      <FormComponent v-if="acaoModal.length > 1" :acao="acaoModal" @open-modal="modalOpen($event)" />
    </Transition>
    <BannerComponent class="z-50"  @open-modal="modalOpen($event)" />
    <SectionOneComponent class="mb-10" @open-modal="modalOpen($event)"  />
    <SectionTwoComponent class="mb-10" @open-modal="modalOpen($event)"   />
    <MapaComponent class="mb-10" />
    <SectionThreeComponent class="mb-10" />
    <SectionFourComponent  />
    <CarouselComponent class="mb-10" />
    <CarouselPlantasComponent class="mb-10" />
    <CarouselComunsComponent @open-modal="modalOpen($event)" />
    <FooterComponent />
  </div>
</template>

<script>

// @ is an alias to /src
import BannerComponent from '@/components/BannerComponent.vue'
import SectionOneComponent from '@/components/SectionOneComponent.vue'
import SectionTwoComponent from '@/components/SectionTwoComponent.vue'
import SectionThreeComponent from '@/components/SectionThreeComponent.vue'
import SectionFourComponent from '@/components/SectionFourComponent.vue'
import CarouselComponent from '@/components/CarouselComponent.vue'
import CarouselPlantasComponent from '@/components/CarouselPlantasComponent.vue'
import MapaComponent from '@/components/MapaComponent.vue'
import CarouselComunsComponent from '@/components/CarouselComunsComponent.vue'
import FooterComponent from '@/components/FooterComponent.vue'
import FormComponent from '@/components/FormComponent.vue'

export default {
  name: 'HomeView',
  components:{
    BannerComponent,
    SectionOneComponent,
    SectionTwoComponent,
    SectionThreeComponent,
    SectionFourComponent,
    CarouselComponent,
    CarouselPlantasComponent,
    MapaComponent,
    CarouselComunsComponent,
    FooterComponent,
    FormComponent
  },
  data() {
    return {
      acaoModal: '',
      isLoaded: false
    }
  },
  methods: {
     modalOpen(acao){
      this.acaoModal = acao
    }
  },
  mounted() {
  
    document.onreadystatechange = () => {
      if(document.readyState == 'complete'){
          this.isLoaded = true;
      }
    }
    if(document.readyState == 'complete'){
        this.isLoaded = true;
    }
    var event_id = new Date().getTime()+"."+Math.random();
    
    window.fbq('track', 'PageView', {}, {eventID: event_id});

    var url = window.location.href;
    var params = 'url='+url+'&userAgent='+window.navigator.userAgent+'&eventId='+event_id;
    var xhttp = new XMLHttpRequest();
    xhttp.open("POST", "https://acheiinterativa.com.br/integracao/capi/sertenge/pageview.php", true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.send(params);

    console.log( 'Home Page:', event_id);
  },


}
</script>
<style scoped>
/*   
  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
  .animate{
    animation: zoom-preload 1.2s ;
  }
  @keyframes zoom-preload {
    0% {
      transform: scale(5);
    }
    100% {
        transform: scale(1);
       
    }
  } */
.whatsapp-fixo {
  position: fixed;
  bottom:10px;
  right:10px;
  z-index: 99;
}
</style>
